import React, { useEffect, useState } from "react";

import HeaderItem from "./BoardElements/HeaderItem/HeaderItem";
import Rating from "./BoardElements/Character/Rating";
import DraggablePlayer from "./Draggable/DraggablePlayer";

import {
	HappyFace,
	HappyFace2X,
	SadFace,
	SadFace2X,
	SkilsIcon,
	TimeIcon,
	PlayerIcon,
	MoneyIcon,
	ModavtionIcon,
} from "images";

import mapDispatchToProps from '../redux/dispatchers';
import { useSelector, connect } from 'react-redux';

import Translate from '../utils/Translate'

const PlayerCard = ({ canEdit, idx, characterName, player, removeCharacter, openProfile, closePanel }) => {
	const [characterImpactData, setCharacterImpactData] = useState({})
	const impactState = useSelector(state => state.impact.players)

	useEffect(() => {
		if (impactState) {
			impactState.forEach( (character) => {
				if (character.id === player.id) {
					setCharacterImpactData(character)
				}
			})
		}
	}, [impactState])

	const openAvatarProfile = () => {
		if (closePanel !== undefined) {
			openProfile(player)
			closePanel()
		}
	}
	
	return (
		<>
			<div className="player-header">
				<HeaderItem
					image={
						<DraggablePlayer
							id={player.id}
							isCard={ true }
							image={player.image}
							name={player.name}
							qty={player.qty}
							removeCharacter={removeCharacter}
							index={ idx }
							onImgClick={ openAvatarProfile }
							canEdit={ canEdit }
						/>
					}
					title={(characterName) ? characterName : player.name}
					characterTitle={player.characterTitle}
					lineHeight={"35px"}
					fontSize={"16px"}
					playerTitle={player.characterTitle}
				/>

				<div className="player__icons">
					<div className="item">
						<TimeIcon width={26} height={30} />
						<p>{player.time}</p>
						{
							(characterImpactData.time !== 0 &&
								<div className={ `impact ${ (characterImpactData.time > 0) ? "up" : "down" }` }>
									{ characterImpactData.time }
									<i class={ `arrow ${ (characterImpactData.time > 0) ? "up" : "down" }` }></i>
								</div>
							)
						}
					</div>
					<div className="item">
						<MoneyIcon width={28} height={32} />
						<p>{player.amount}</p>
						{
							(characterImpactData.amount !== 0 &&
								<div className={ `impact ${ (characterImpactData.amount > 0) ? "up" : "down" }` }>
									{ characterImpactData.amount }
									<i class={ `arrow ${ (characterImpactData.amount > 0) ? "up" : "down" }` }></i>
								</div>
							)
						}
					</div>
				</div>
			</div>
			<div className="player__skills-and-motivation">
				<div className="firstDiv">
					<HeaderItem
						image={<SkilsIcon width={20} height={29} />}
						title={ <Translate alias="Board" word="SKILLS & KNOWLEDGE" /> }
						lineHeight={"18px"}
						fontSize={"10px"}
					/>
					{
						(characterImpactData.skills !== 0 &&
							<div className={ `impact ${ (characterImpactData.skills > 0) ? "up" : "down" }` }>
								{ characterImpactData.skills }
								<i class={ `arrow ${ (characterImpactData.skills > 0) ? "up" : "down" }` }></i>
							</div>
						)
					}
					<div className="feedback skills">
						<p>
							{player.skills > 0 ? `+${player.skills}` : player.skills}
						</p>
						{player.skills !== 0 && (
							<picture>
								<source
									srcSet={`${player.skills > 0 ? HappyFace2X : SadFace2X } 2x`}
								/>
								<img
									src={player.skills > 0 ? HappyFace : SadFace}
									alt={player.skills > 0 ? "Happy Face" : "Sad Face"}
								/>
							</picture>
						)}
					</div>
				</div>
				<div className="secondDiv">
					<HeaderItem
						image={<ModavtionIcon width={20} height={24} />}
						title={ <Translate alias="Board" word="MOTIVATION &  SELF-CONFIDENCE" /> }
						lineHeight={"18px"}
						fontSize={"10px"}
					/>
					{
						(characterImpactData.motivation !== 0 &&
							<div className={ `impact ${ (characterImpactData.motivation > 0) ? "up" : "down" }` }>
								{ characterImpactData.motivation }
								<i class={ `arrow ${ (characterImpactData.motivation > 0) ? "up" : "down" }` }></i>
							</div>
						)
					}
					<div id={`${player.id}_motivation`} className="feedback motivation">
						<p>
							{player.motivation > 0 ? `+${player.motivation}` : player.motivation}
						</p>
						{player.motivation !== 0 && (
							<picture>
								<source
									srcSet={`${player.motivation > 0 ? HappyFace2X : SadFace2X } 2x`}
								/>
								<img
									src={player.motivation > 0 ? HappyFace : SadFace}
									alt={player.motivation > 0 ? "Happy Face" : "Sad Face"}
								/>
							</picture>
						)}
					</div>
				</div>
			</div>
			<div className="player__rating">
				<div>
					<PlayerIcon />
					<p>{ <Translate alias="Board" word="RETENTION RISK" /> }</p>
				</div>
				<Rating rating={player.risk} />
				{
					(characterImpactData.risk !== 0 &&
						<div className={ `impact risk ${ (characterImpactData.risk < 0) ? "up" : "down" }` }>
							{ characterImpactData.risk }
							<i class={ `arrow ${ (characterImpactData.risk > 0) ? "up" : "down" }` }></i>
						</div>
					)
				}
			</div>
		</>
	);
};

export default connect(null, mapDispatchToProps)(PlayerCard);
