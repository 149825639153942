/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const TimeIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '44'}px`}
      width={`${width || '41'}px`}
      viewBox="0 0 41 44"
      css={css`
        fill: ${color || Colors.primaryColor};
        max-width: ${width || '41'}px;
        max-height: ${height || '44'}px;
        height: ${height || '44'}px;
        width: ${width || '41'}px;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9375 0.792931L13.7973 3.42942C9.23439 4.91685 5.20477 7.67538 2.14511 11.4094L-6.10352e-05 9.65255C3.3956 5.50723 7.86979 2.44519 12.9375 0.792931ZM41.7502 9.65121L39.6051 11.4097C36.5438 7.67562 32.5142 4.91709 27.952 3.43045L28.8119 0.793168C33.878 2.44464 38.3522 5.50826 41.7502 9.65121ZM20.8754 6.09127C31.8041 6.09127 40.6645 14.9525 40.6645 25.8804C40.6645 32.1265 37.7689 37.6911 33.2511 41.3174L35.5476 45.6624C35.9058 46.3384 35.6467 47.1776 34.9699 47.5358C34.7631 47.6459 34.5412 47.6966 34.3233 47.6966C33.8256 47.6966 33.3446 47.428 33.0958 46.9589L30.9554 42.9086C28.0027 44.6607 24.5571 45.6703 20.8754 45.6703C17.1936 45.6703 13.7488 44.6607 10.7962 42.9094L8.65416 46.9596C8.40612 47.428 7.9251 47.6966 7.42744 47.6966C7.20872 47.6966 6.98684 47.6459 6.78001 47.5358C6.10325 47.1776 5.84491 46.3384 6.2031 45.6624L8.50042 41.3174C3.98186 37.6911 1.08623 32.1265 1.08623 25.8804C1.08623 14.9525 9.94665 6.09127 20.8754 6.09127ZM20.8754 8.86485C11.4935 8.86485 3.85982 16.4986 3.85982 25.8804C3.85982 35.2638 11.4935 42.8967 20.8754 42.8967C30.258 42.8967 37.8909 35.2638 37.8909 25.8804C37.8909 16.4986 30.258 8.86485 20.8754 8.86485ZM20.7944 14.1267H20.7904C20.0265 14.1267 19.406 14.7449 19.4036 15.5096L19.3751 25.164L14.2091 28.5953C13.5712 29.0177 13.3968 29.8791 13.8208 30.5178C14.0886 30.9188 14.5285 31.1375 14.9778 31.1375C15.2409 31.1375 15.5079 31.0615 15.7433 30.9061L21.4727 27.1C21.5686 27.0366 21.651 26.9597 21.7255 26.8789C21.9838 26.6277 22.1455 26.2774 22.1471 25.8899L22.1772 15.5183C22.1796 14.752 21.5607 14.1291 20.7944 14.1267Z"
        fill={`${color || Colors.primaryColor}`}
      />
    </svg>
  );
};

export default TimeIcon;

TimeIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
