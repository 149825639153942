import React from 'react';
import { useDrop } from 'react-dnd';

// Components
import DraggablePlayer from '../Draggable/DraggablePlayer';

export default function Card(props) {
	const { cardIndex, category, title, players } = props;

	//{ isOver, canDrop }
	const [, drop] = useDrop({
		accept: "Character",
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (item.qty > 0) {
				if (item.cardIndex !== cardIndex || item.cardCategory !== category) {
					props.appendCharacter(cardIndex, item);
					
					return item
				}
			}
		},
	})

	const canDrop = (props.canEdit) ? drop : null

	return (
		<div className="cardWrapper">
			<div className="card-item" ref={ canDrop }>
				<div className="background"></div>
				<p>{ title }</p>
			</div>
			{players && (
				<div className="characterWrapper">
					{
						players.map( (player, index) => (
							<DraggablePlayer
								key={index}
								characterIndex={ index }
								id={player.id}
								image={player.image}
								name={player.name}
								qty={player.qty}
								cardIndex={cardIndex}
								cardCategory={category}
								removeCharacter={ props.removeCharacter }
								canEdit={ props.canEdit }
							/>
						))
					}
				</div>
			)}
		</div>
	);
}
