import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Colors } from './Theme';

const Button = styled.div`
  min-width: ${(props) => (props.winWidth ? `${props.winWidth}` : '160px')};
  display: inline-block;
  a,
  button {
    text-align: center;
    font-family: Cairo, Arial, sans-serif;
    font-size: 16;
    font-weight: 700;
    line-height: 1;
    color: ${Colors.white};
    border: 1px solid
      ${(props) =>
        props.backgrounColor
          ? `${props.backgrounColor}`
          : `${Colors.primaryColor}`};
    padding: 22px;
    object-fit: contain;
    border-radius: 7.2px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: ${(props) =>
      props.backgrounColor
        ? `${props.backgrounColor}`
        : `${Colors.primaryColor}`};
    min-width: 100%;
    display: inline-block;
    &:hover {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
`;

export default function ButtonWrapper(props) {
  return (
    <Button className="primary-button" {...props}>
      {props.children}
    </Button>
  );
}

ButtonWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  winWidth: PropTypes.string,
  backgrounColor: PropTypes.string,
};
