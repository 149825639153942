import React from 'react';
import { useDrop } from 'react-dnd';

import trashIcon from '../../images/trash.png';
import redTrashIcon from '../../images/trash-red.png';

const DeleteCharacter = (props) => {

	//{ isOver, canDrop }
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: "Character",
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
		drop: (item) => {
			if (item.qty > 0) {
				props.increaseCharacter(item);
				return item
			}
		},
	})

	const isCanDrop = isOver && canDrop;

	return (
		<div className="deleteWrapper">
			<div className="delete-item" ref={ drop }>
				{
					(isCanDrop) ?
						<img src={redTrashIcon} />
						:
						<img src={trashIcon} />
				}
			</div>
		</div>
	);
}


export default DeleteCharacter;