const urls = {
	backendURL: process.env.REACT_APP_BACKEND_URL,
	socketEndpoint: process.env.REACT_APP_SOCKET_URL,
	authenticate: process.env.REACT_APP_BACKEND_URL + "/api/player/login",
	logout: process.env.REACT_APP_BACKEND_URL + "/api/authenticate",
	teamDetails: process.env.REACT_APP_BACKEND_URL + "/api/team-details",
	renameTeam: process.env.REACT_APP_BACKEND_URL + "/api/team/rename",
	activeCounter: process.env.REACT_APP_BACKEND_URL + "/api/counter/active",
	coursesLink: process.env.REACT_APP_BACKEND_URL + "/api/courses",
	eventsLink: process.env.REACT_APP_BACKEND_URL + '/api/active/event',
	characterProfileLink: process.env.REACT_APP_BACKEND_URL + '/api/character/profile',
}

export default urls;
