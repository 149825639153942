import styled from '@emotion/styled';
import { Colors } from 'utils';

const PlayerWrapper = styled.div`
  border-bottom: ${(props) => props.isOpen ? `1px solid ${Colors.ghostGray}` : 'none'};
  .player {
	&-header {
	  width: 100%;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  & > div {
		width: 50%;
	  }
	}
	&__icons {
	  display: flex;
	  justify-content: center;
	  align-items: center;

	  .item {
		display: flex;
		justify-content: center;
		align-items: center;
		p {
		  font-weight: 700;
		  font-size: 16px;
		  line-height: 30px;
		  margin-left: 8px;
		  color: ${Colors.black};
		}
		& + .item {
		  margin-left: 30px;
		}
	  }
	}
	&__skills-and-motivation {
	  display: flex;
	  margin-top: 12px;

	  & > .firstDiv {
		width: 45%;
		border-right: 1px solid #ccc;
	  }
	  & > .secondDiv {
		  width: 55%;
		  padding-left: 10px;
	  }
	  & > div {
		display: flex;
		padding-right: 10px;
		p {
		  max-width: 98px;
		  text-align: left;
		}
	  }
	  .feedback {
		width: 38px;
		height: 38px;
		border-radius: 12px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		p {
		  margin: 0;
		  color: ${Colors.white};
		  font-weight: 700;
		  font-size: 16px;
		}
		&.skills {
			background: ${(props) => {
					if (props.player.skills === 0) {
						return Colors.gray;
					}else{
						return props.player.skills > 0 ? Colors.yellow : Colors.red;
					}
				}
			};
			& p {
				color: ${(props) =>
					props.player.skills >= 0 ? Colors.blue : Colors.white};
			}
		}
		&.motivation {
			background: ${(props) => {
					if (props.player.motivation === 0) {
						return Colors.gray;
					}else{
						return props.player.motivation > 0 ? Colors.yellow : Colors.red;
					}
				}
			};
			& p {
				color: ${(props) =>
					props.player.motivation >= 0 ? Colors.blue : Colors.white};
			}
		}
		img {
		  width: 15px;
		  height: 15px;
		  position: absolute;
		  right: -5px;
		  top: -5px;
		}
	  }
	}
	&__rating {
	  display: flex;
	  align-items: center;
	  margin-top: 9px;
	  & > div {
		display: flex;
		align-items: center;
		p {
		  font-weight: 500;
		  font-size: 10px;
		  margin-left: 7px;
		  margin-right: 15px;
		}
	  }
	}
  }
`;

export default PlayerWrapper
