/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const ScoreIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '44'}px`}
      width={`${width || '37'}px`}
      viewBox="0 0 37 44"
      css={css`
        fill: ${color || Colors.black};
        max-width: ${width || '37'}px;
        max-height: ${height || '44'}px;
        height: ${height || '44'}px;
        width: ${width || '37'}px;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0877 21.7983C25.1606 24.4733 22.6281 26.3479 19.6858 26.3479H19.5938V30.5814H25.8864C26.5983 30.5814 27.1754 31.1585 27.1754 31.8704V41.4219H28.4713C29.1832 41.4219 29.7604 41.999 29.7604 42.7109C29.7604 43.4228 29.1832 44 28.4713 44H8.13805C7.42614 44 6.84899 43.4228 6.84899 42.7109C6.84899 41.999 7.42614 41.4219 8.13805 41.4219H9.4339V31.8704C9.4339 31.1585 10.0111 30.5814 10.723 30.5814H17.0157V26.3479H16.9236C13.9814 26.3479 11.4488 24.4733 10.5217 21.7983C4.49482 20.7325 0.0317383 15.4654 0.0317383 9.27128C0.0317383 8.55937 0.608895 7.98222 1.3208 7.98222H8.83036L8.67748 6.59235L8.67714 6.58934L8.10969 1.43C8.06964 1.0658 8.18652 0.70168 8.43101 0.428828C8.6755 0.155891 9.02458 0 9.39102 0H27.2184C27.5848 0 27.9339 0.155891 28.1784 0.428828C28.4229 0.70168 28.5398 1.0658 28.4997 1.43L27.7792 7.98222H35.2887C36.0006 7.98222 36.5778 8.55937 36.5778 9.27128C36.5778 15.4656 32.1148 20.7325 26.0877 21.7983ZM12.012 41.4219H24.5974V33.1595H12.012V41.4219ZM33.9181 10.5603C33.4017 14.6132 30.4821 17.9695 26.562 19.0497L27.4956 10.5603H33.9181ZM25.4947 5.16974L25.7797 2.57812H10.8297L11.1147 5.16974H25.4947ZM2.69133 10.5603H9.11378L10.0474 19.0498C6.12737 17.9695 3.20773 14.6132 2.69133 10.5603ZM11.3982 7.74787L12.7489 20.029C12.9835 22.1616 14.7782 23.7698 16.9234 23.7698H19.6857C21.8312 23.7698 23.626 22.1616 23.8605 20.029L25.0579 9.14074L25.0583 9.13782L25.2112 7.74787H11.3982Z"
        fill={`${color || Colors.black}`}
      />
    </svg>
  );
};

export default ScoreIcon;

ScoreIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
