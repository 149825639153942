import React, { Component, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Colors } from 'utils';
import {
  TeamIcon,
  TeamLead,
  OrganizaitonIcon,
  ScoreIcon,
  MoneyIcon,
  TimeIcon,
} from 'images';
import axios from 'axios';
import moment from 'moment';

import mapDispatchToProps from "../../redux/dispatchers";
import * as RealtimeConnection from "../../utils/RealtimeConnection";
import urls from "../../utils/urls";
import Translate from '../../utils/Translate'

// Components
import HeaderItem from './HeaderItem/HeaderItem';
import DeleteCharacter from '../Droppable/DeleteCharacter';

// Images
import logIcon from '../../images/log-icon.svg'



let counterIntervalTimer = undefined

const Header = (props) => {
	const [teamName, setTeamName] = useState("")
	const [openTeamForm, setOpenTeamForm] = useState(false)

	const [openFiles, setOpenFiles] = useState(false)
	const [files, setFiles] = useState([])

	const [counter, setCounter] = useState(undefined)
	const [counterData, setCounterData] = useState({
		name: "",
		counterMinutes: 0,
		counterSeconds: 0
	})

	const sessionState = useSelector(state => state.session)

	useEffect(() => {
		if(sessionState.files) {
			setFiles(sessionState.files)
		}
	}, [sessionState])

	useEffect(() => {
		if (props.teamName) {
			setTeamName(props.teamName)
		}else{
			setTeamName("Team-" + window.teamId)
		}
	}, [props])

	useEffect(() => {
		fetchCounter()
	}, [])

	useEffect(() => {
		if (props.session.timer.duration !== undefined) {
			const counterTimer = { ...props.session.timer };

			if (props.session.timer.duration === 0) {
				setCounterData({
					name: counterData.name,
					counterMinutes: 0,
					counterSeconds: 0
				})
				if (counterIntervalTimer) {
					clearInterval(counterIntervalTimer)
				}
			}else{
				fetchCounter()
			}
		}
	}, [props.session.timer])

	useEffect(() => {
		if (counter) {
			counterIntervalTimer = setInterval(() => {
				calculateCounter(counter)
			}, 1000);
		}
	}, [counter])

	const fetchCounter = async () => {
		const result = await axios.post(urls.activeCounter, {
			id: window.sessionId
		})

		let response = result.data;
		if (response.data) {
			//this.counterTimer = response.data
			calculateCounter(response.data)
		}
	}

	const calculateCounter = (timerData) => {
		const currentDate = moment().utc()
		const startDate = moment.utc(timerData.createdAt);
		const endDate = startDate.clone().add(parseInt(timerData.duration), 'minutes');

		const minutes = endDate.diff(currentDate)
		if (minutes > 0) {
			let minutesValue = 0
			let secondsValue = 0
			if (Math.ceil((minutes / 1000) % 60) === 60) {
				minutesValue = Math.ceil(minutes / 1000 / 60);
				secondsValue = 0;
			}else{
				if (minutes > 60000){
					minutesValue = Math.ceil(minutes / 1000 / 60) - 1;
				}else{
					minutesValue = 0
				}
				secondsValue = Math.ceil((minutes / 1000) % 60);
			}

			setCounterData({
				name: timerData.name,
				counterMinutes: minutesValue,
				counterSeconds: secondsValue
			})
			if (!counter) {
				setCounter(timerData)
			}else if (counter.createdAt !== timerData.createdAt) {
				setCounter(timerData)
			}
		}else{
			setCounterData({
				name: timerData.name,
				counterMinutes: 0,
				counterSeconds: 0
			})
			if (counterIntervalTimer) {
				clearInterval(counterIntervalTimer)
			}
		}
	}

	const updateTeamName = () => {
		const dataObj = {
			playerId: window.playerId,
			teamId: window.teamId,
			token: window.token,
			name: teamName
		};
		changeTeamName(dataObj);

		// Update Socket
		RealtimeConnection.changeTeamName({
			value: dataObj.name
		})
	}

	const changeTeamName = async (data) => {
		setOpenTeamForm(false)

		const result = await axios.post(urls.renameTeam, data)
	}


	let score = (5 * props.session.team);
	score += (5 * props.session.teamLead);
	score += (10 * props.session.organization);

	let charactersSkills = 0;
	let chatactersMotivation = 0;
	let chatactersRetention = 0;
	props.players.forEach( player => {
	charactersSkills += player.skills;
	chatactersMotivation += player.motivation;

	switch(player.risk) {
		case 1:
			chatactersRetention += 2;
			break;
		case 2:
			chatactersRetention += 1;
			break;
		case 3:
			chatactersRetention += 0;
			break;
		case 4:
			chatactersRetention -= 1;
			break;
		case 5:
			chatactersRetention -= 2;
			break;
		default:
			break;
	}
	});
	score += (2 * charactersSkills);
	score += (2 * chatactersMotivation);
	score += chatactersRetention; //(2 * chatactersRetention);

	score += (0.5 * props.session.time);
	score += (0.5 * props.session.amount);

	//score *= 2;
	score = Math.ceil(score)

	const increaseCharacter = (character) => {
		props.increaseCharacterAction(character)
	}

	return (
		<React.Fragment>
			<div className={ `headerWrapper ${ (localStorage.isRTL === "1") ? "rtl" : "" }` }>
				<h1 className="visually-hidden">Main Header</h1>
				{
					(counterData.name && (counterData.counterMinutes > 0 || counterData.counterSeconds > 0)) ?
						<div className='boardContainer'>
							<div className="counter-title">
								{ counterData.name }
							</div>
						</div>
						:
						<div style={{ height: 25 }}>&nbsp;</div>
				}
				<div className='boardContainer' style={{ display: "flex", justifyContent: "space-between" }}>
					<div className='playerNameSection' style={{ flex: 1 }}>
						<b>Player:</b> { window.playerName }
					</div>
					<div
						className="counter-count"
						style={{ width: 200, color: (counterData.counterMinutes === 0 && counterData.counterSeconds > 0) ? "#FF0000" : "#0A2955" }}
					>
						{
							(counterData.name && (counterData.counterMinutes > 0 || counterData.counterSeconds > 0)) ?
								<b>
									{counterData.counterMinutes > 9 ? counterData.counterMinutes : `0${counterData.counterMinutes}`} :{" "}
									{counterData.counterSeconds > 9 ? counterData.counterSeconds : `0${counterData.counterSeconds}`}
								</b>
								:
								null
						}
					</div>
					<div className='files-section' style={{ flex: 1 }} onClick={ () => setOpenFiles(prevState => !prevState) }>
						Files 
						<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="menu-toggle-icon"><polyline points="9 18 15 12 9 6"></polyline></svg>
					</div>
					{
						(openFiles) ?
							<div className='files-container'>
								{
									files.map((file, index) => {
										return (
											<div className="file-row" key={ index }>
												<div className="file-link">
													<a href={ `${ urls.backendURL }/uploads/sessions/images/${ file.file }` } target="_blank" rel="noopener noreferrer">{ file.name }</a>
												</div>
											</div>
										)
									})
								}
							</div>
							:
							null
					}
				</div>
				<div className="boardContainer header-container">
					<div className="team-nav">
						<div className="item-nav">
						<div id="teamValue" className="item">
							<HeaderItem
								image={<TeamIcon />}
								title={ <Translate alias="Board" word="TEAM" /> }
								number={props.session.team}
								numberColor={Colors.blue}
								impact={ props.impact.team }
							/>
						</div>
						<div id="teamLeadValue" className="item">
							<HeaderItem
								image={<TeamLead />}
								title={ <Translate alias="Board" word="TEAM LEADER" /> }
								number={props.session.teamLead}
								numberColor={Colors.blue}
								impact={ props.impact.teamLeader }
							/>
						</div>
						<div id="organizationValue" className="item">
							<HeaderItem
								image={<OrganizaitonIcon />}
								title={ <Translate alias="Board" word="ORGANIZATION" /> }
								number={props.session.organization}
								numberColor={Colors.blue}
								impact={ props.impact.organization }
							/>
						</div>
						<div className="item">
							<HeaderItem
								image={<ScoreIcon />}
								title={ <Translate alias="Board" word="SCORE" /> }
								number={score}
								numberColor={Colors.blue}
								impact={ 0 }
							/>
						</div>
						</div>
					</div>
					<div className="team-name-nav">
						<div className="item-nav">
							<div className="item">
								<div title="TEAMNAME" className="header-item css-mo0sqo-HeaderItemWrapper e1ejgrad0">
									<div>
										{
											(openTeamForm) ?
												<div className="details-form">
													<div>
														<input type="text" value={ teamName } onChange={e => setTeamName(e.target.value) } />
													</div>
													<div>
														<div
															className="team-name-btn"
															onClick={ updateTeamName }
														>
															{ <Translate alias="Board" word="Save" /> }
														</div>
													</div>
												</div>
											:
												<div className="details-wrapper" onClick={ () => setOpenTeamForm(true) }>
													<p>{ <Translate alias="Board" word="TEAM NAME" /> }</p>
													<p className="number">
														{ teamName }
													</p>
												</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="result-nav">
						<div className="item-nav">
							<div id="timeValue" className="item">
								<TimeIcon />
								<p>{props.session.time}</p>
							</div>
							<div id="amountValue" className="item">
								<MoneyIcon />
								<p>{props.session.amount}</p>
							</div>
						</div>
					</div>
				</div>

				<div className="logWrapper">
					<div className="log-item" onClick={ props.openLog }>
						<img src={ logIcon } />
					</div>
				</div>
				{
					(props.canEdit &&
						<DeleteCharacter
							increaseCharacter={ increaseCharacter }
						/>
					)
				}
			</div>
		</React.Fragment>
	)

}

const mapStateToProps = (state) => {
  return {
	session: state.session,
	players: state.startingKit.players,
	impact: state.impact.team
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
