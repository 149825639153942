export const Colors = {
  primaryColor: '#005b97',
  secondaryColor: '#5cb3db',
  black: '#000',
  white: '#fff',
  red: '#d11349',
  yellow: '#fdb913',
  green: '#00a94f',
  gray: '#f7f7f7',
  lightGray: '#d5d5d5',
  athensGray: '#eaebed',
  blue: '#1B5B97', //'#002C59',
  ghostGray: '#cdcfd5',
};
