import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const Container = styled.div`
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : '1042px')};
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export default function ContainerWrapper(props) {
  return <Container {...props}>{props.children}</Container>;
}

ContainerWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  maxWidth: PropTypes.string,
};
