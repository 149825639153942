import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import moment from 'moment';

import mapDispatchToProps from '../../redux/dispatchers';
import * as RealtimeConnection from '../../utils/RealtimeConnection';

import {
	MainLogo,
	MainLogo2X,
	LongLogo,
	LongLogo2X,
	ArrowRight,
	HappyFace,
	HappyFace2X,
	SadFace,
	SadFace2X,
	SkilsIcon,
	TimeIcon,
	PlayerIcon,
	MoneyIcon,
	ModavtionIcon,
} from 'images';
import { Colors } from 'utils';

// Components
import HeaderItem from './HeaderItem/HeaderItem';
import Rating from './Character/Rating';

import DraggablePlayer from '../Draggable/DraggablePlayer';

import PlayerWrapper from './../PlayerWrapper';
import PlayerCard from 'components/PlayerCard';
import urls from 'utils/urls';

//overflow-y: scroll;
//overflow-x: hidden;
const PlayersBarWrapper = styled.div`
	position: fixed;
	height: 100vh;
	top: 0;
	padding: ${(props) => (props.isOpen ? '23px 30px' : '23px 20px')};
	background: ${Colors.white};
	border-radius: 0 12px 12px 0;
	text-align: ${(props) => (props.isOpen ? 'left' : 'center')};
	z-index: 5;
	min-width: ${(props) => (props.isOpen ? '415px' : '85px')};
	bottom: 0px;
	overflow-y: auto;

	.palyersBar_header {
		display: ${(props) => (props.isOpen ? 'flex' : 'block')};
		align-items: center;
		justify-content: space-between;
		h1 {
			margin-bottom: 45px;
		}
		button {
			background: ${Colors.athensGray};
			border-radius: 12px;
			padding: 10px;
			border: none;
			margin-bottom: 45px;
			transform: ${(props) =>
				props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
		}
	}

	&::after {
		content: ${(props) => (props.isOpen ? 'none' : '""')};
		width: 5px;
		height: 120px;
		display: inline-block;
		background: ${Colors.ghostGray};
		position: absolute;
		top: 50%;
		left: 110%;
		transform: translateY(-50%);
		border-radius: 100px;
	}
`;

const PlayersWrapper = styled.div`
	scrollbar-color: ${Colors.ghostGray} transparent;
	scrollbar-width: thin;
	max-height: calc(90vh - 130px);
	min-height: 550px;
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 0 transparent;
		border-radius: 10px;
		background-color: transparent;
	}
	&::-webkit-scrollbar {
		width: 5px;
		background-color: transparent;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 0 transparent;
		background-color: ${Colors.ghostGray};
	}
	& > div {
		margin: auto;
		& + div {
			margin-top: 40px;
		}
	}
`;

let currentAnimationIndex = 0
let currentSessionAnimationIndex = 0
const PlayersBar = (props) => {

	const [isOpen, setIsOpen] = useState(false)
	const [socket, setSocket] = useState(undefined)
	const [animateObj, setAnimateObj] = useState([])
	const [sessionAnimateObj, setSessionAnimateObj] = useState([])

	useEffect(() => {
		setInterval(() => {
			checkAvailableAnimation()
		}, [500])
	}, [])

	useEffect(() => {
		setSocket(props.socket)
	}, [props.socket])

	useEffect(() => {
		if (socket) {
			socket.on('update-character', (data) => {
				if (data.type !== "risk") {
					currentAnimationIndex += 1
					setAnimateObj(prevState => {
						return [
							...prevState,
							{
								id: currentAnimationIndex,
								type: data.type,
								playerId: props.players[data.index].id,
								value: data.value,
								time: new moment()
							}
						]
					})
				}
			});

			socket.on('update-session', (data) => {
				const teamElement = document.getElementById(data.type + "Value");
				if (teamElement) {
					const rect = teamElement.getBoundingClientRect();
					const css = document.styleSheets[0]

					currentSessionAnimationIndex += 1
					const animName = `${data.type}_${currentSessionAnimationIndex}`
					css.insertRule(`
						@keyframes ${animName} {
							to {
								left: ${rect.left}px;
								top: ${rect.top}px;
							}
						}`, css.cssRules.length);
					setSessionAnimateObj(prevState => {
						return [
							...prevState,
							{
								id: currentSessionAnimationIndex,
								type: data.type,
								value: data.value,
								animName: animName,
								time: new moment()
							}
						]
					})
				}

			});
		}
	}, [socket])

	const _handelToggleBar = () => {
		setIsOpen( prevState => !prevState )
	};

	const removeCharacter = (index, character) => {
		props.decreaseCharacter(character)

		RealtimeConnection.decreaseCharacter({
			id: character.id,
			index: character.index,
			qty: 0
		});
	}

	const checkAvailableAnimation = () => {
		const currentTime = new moment()
		setAnimateObj( prevState => {
			return prevState.filter( anim => {
				if (currentTime.diff(anim.time) > 1000) {
					return false
				}
				return true
			})
		})

		setSessionAnimateObj( prevState => {
			return prevState.filter( anim => {
				if (currentTime.diff(anim.time) > 1000) {
					return false
				}
				return true
			})
		})
	}

	return (
		<div className="leftBar">
			<PlayersBarWrapper isOpen={isOpen}>
				<div className="palyersBar_header">
					<h1>
						<picture>
							<source
								srcSet={`${
									isOpen ? LongLogo2X : MainLogo2X
								} 2x`}
							/>
							<img
								src={isOpen ? LongLogo : MainLogo}
								alt="Simdustry Logo"
							/>
						</picture>
					</h1>
					{props.partnerLogo && (
						<h1>
							<picture>
								<img
									src={urls.backendURL + props.partnerLogo}
									alt=""
									width="60"
								/>
							</picture>
						</h1>
					)}

					<div>
						<button onClick={() => _handelToggleBar()}>
							<picture>
								<img src={ArrowRight} alt="Expand Player Bar" />
							</picture>
						</button>
					</div>
				</div>
				<PlayersWrapper isOpen={isOpen}>
					{
						props.players.map((player, idx) => {
							if (player.id === "player_3" && !props.whiteCharacterStatus) {
								return null
							}
							return (
								<PlayerWrapper
									id={ player.id }
									key={`${player.name}-${idx}`}
									isOpen={isOpen}
									player={player}
								>
									<div className="player__header">
										{isOpen ? (
											<PlayerCard
												key={`${player.name}-${idx}`}
												player={player}
												idx={idx}
												removeCharacter={removeCharacter}
												closePanel={_handelToggleBar}
												canEdit={props.canEdit}
											/>
										) : (
											<DraggablePlayer
												id={player.id}
												isCard={true}
												image={player.image}
												name={player.name}
												qty={player.qty}
												index={idx}
												onImgClick={() =>
													props.openProfile(player)
												}
												removeCharacter={removeCharacter}
												canEdit={props.canEdit}
											/>
										)}
									</div>
								</PlayerWrapper>
							)
						})
					}
				</PlayersWrapper>
				<div id="AppendedElement">
					{
						animateObj.map( obj => {
							return (
								<React.Fragment key={ obj.id }>
									{
										(isOpen) ?
											<div
												id={ `Animation_${obj.id}` }
												className={ `flyingObj ${obj.playerId}_${obj.type}` }
												//style={{ animation: obj.animName + " " + "2s" }}
											>
												<p>{obj.value}</p>
											</div>
											:
											<div
												key={ obj.id }
												id={ `Animation_${obj.id}` }
												className={ `flyingObj ${obj.playerId}` }
												//style={{ animation: obj.animName + " " + "2s" }}
											>
												<p>{obj.value}</p>
											</div>
									}
								</React.Fragment>
							)
						})
					}
				</div>
			</PlayersBarWrapper>
			{
				sessionAnimateObj.map( obj => {
					return (
						<div
							key={ obj.id }
							id={ `Session_Animation_${obj.id}` }
							className={ `flyingObj ${obj.type}` }
							style={{ animation: obj.animName + " " + "1s" }}
						>
							<p>{obj.value}</p>
						</div>
					)
				})
			}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		whiteCharacterStatus: state.session.whiteCharacterStatus,
		players: state.startingKit.players,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayersBar);
