import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Cards from '../Cards';
import Cards4x3 from '../Cards4x3';
import { ArrowIcon } from 'images';

const ArrowView = ({
	board: { low, medium, high },
	localizations,
	canEdit,
}) => {
	return (
		<div className="boardWrapper">
			<span className="potential-text">
				{localizations.GridBox ? localizations.GridBox['yAxis'] : ''}
			</span>
			<span className="potential-arrow">
				<ArrowIcon />
			</span>
			<span className="performer-arrow">
				<ArrowIcon />
			</span>
			<div
				className={`board-wrapper ${
					localizations.GridBox &&
					localizations.GridBox['type'] === '4x3'
						? 'fourColumn'
						: 'threeColumn'
				}`}
			>
				<Cards canEdit={canEdit} cards={low} category="low" />
			</div>
			<div
				className={`board-wrapper ${
					localizations.GridBox &&
					localizations.GridBox['type'] === '4x3'
						? 'fourColumn'
						: 'threeColumn'
				}`}
			>
				<Cards canEdit={canEdit} cards={medium} category="medium" />
			</div>
			<div
				className={`board-wrapper ${
					localizations.GridBox &&
					localizations.GridBox['type'] === '4x3'
						? 'fourColumn'
						: 'threeColumn'
				}`}
			>
				<Cards canEdit={canEdit} cards={high} category="high" />
			</div>
			<span className="performance-text">
				{localizations.GridBox ? localizations.GridBox['xAxis'] : ''}
			</span>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		board: state.board,
		localizations: state.session.localizations,
	};
};

export default connect(mapStateToProps)(ArrowView);
