import React, { Component } from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import axios from 'axios';

import i18n from './i18n';

import { GlobalStyles } from 'utils';
import urls from './utils/urls';

// Components
import reducer from './redux/index';
import BoardPage from './components/BoardPage';
import HomeComponent from './components/HomeComponent';

// CSS
import './css/common.css';
import './css/board.css';
import './css/mediaQuery.css';
import './css/header.css';
import './css/rtl.css';

// Images
import loaderImg from './images/loader.gif';

const store = createStore(reducer);

function findGetParameter(parameterName) {
	let result = null,
		tmp = [];
	let items = window.location.search.substr(1).split('&');
	for (let index = 0; index < items.length; index++) {
		tmp = items[index].split('=');
		if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
	}
	return result;
}

window.sessionId = parseInt(findGetParameter('session_id'));
window.playerId = parseInt(findGetParameter('user_id'));
window.adminId = parseInt(findGetParameter('admin_id'));
window.teamId = parseInt(findGetParameter('team_id'));
window.token = findGetParameter('token');

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			isAuthorized: false,
			notAuthorizedMessage: '',
			canEdit: false,
			isLoggedOut: false,
			localizations: {},
			languages: [],
			partnerLogo: '',
		};
	}

	componentDidMount() {
		this.fetchAuthenticate();
	}

	async fetchAuthenticate() {
		const result = await axios.post(
			urls.authenticate,
			{
				playerId: !isNaN(window.playerId) ? window.playerId : 0,
				adminId: !isNaN(window.adminId) ? window.adminId : 0,
				teamId: window.teamId,
				token: window.token,
				sessionId: window.sessionId,
				langId: localStorage.langId,
			},
			{ headers: { 'content-type': 'application/json' } }
		);

		if (result.data.success) {
			let response = result.data;

			if (!isNaN(window.adminId)) {
				window.playerId = window.adminId;
				window.playerName =
					response.player.firstName + ' ' + response.player.lastName;
			} else {
				window.playerName = response.player.name;
			}

			window.staticWords = response.localizations.staticWords;

			this.setState({
				loading: false,
				isAuthorized: true,
				languages: response.languages,
				localizations: response.localizations,
				teamName: response.teamData
					? response.teamData.name
					: response.player.Teams[0].name,
				canEdit: response.canEdit, //response.data.can_edit,
				isAdmin: !isNaN(window.adminId) ? true : false, //response.data.is_admin,
				partnerLogo: response.partner?.logo,
			});
		} else {
			this.setState({
				loading: false,
				isAuthorized: false,
				notAuthorizedMessage: result.data.message,
			});
		}
	}

	render() {
		return (
			<Provider store={store}>
				<I18nextProvider i18n={i18n}>
					{this.state.isAuthorized ? (
						<>
							<GlobalStyles />
							<BoardPage
								canEdit={this.state.canEdit}
								languages={this.state.languages}
								localizations={this.state.localizations}
								teamName={this.state.teamName}
								partnerLogo={this.state.partnerLogo}
							/>
						</>
					) : (
						<div className="AuthorizationContainer">
							<div className="relativeContainer">
								{this.state.loading ? (
									<div className="NotAuthorizedContainerLoaderImg">
										<div className="loaderContainer">
											<img src={loaderImg} />
										</div>
									</div>
								) : (
									<HomeComponent
										isLoggedOut={this.state.isLoggedOut}
										notAuthorizedMessage={
											this.state.notAuthorizedMessage
										}
									/>
								)}
							</div>
						</div>
					)}
				</I18nextProvider>
			</Provider>
		);
	}
}

export default App;
