import { Player1, Player2, Player3, Player4, Player5, Player6 } from 'images';

const initState = {
	players: [
		{
			id: 'player_6',
			//image: Player6,
			name: 'Team Leader',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0,
			characterTitle: 'TEAM LEADER',
			qty: 0
		},
		{
			id: 'player_1',
			//image: Player1,
			name: 'Gold',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0,
			characterTitle: 'TEAM MEMBER GOLD',
			qty: 0
		},
		{
			id: 'player_4',
			//image: Player4,
			name: 'Green',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0,
			characterTitle: 'TEAM MEMBER GREEN',
			qty: 0
		},
		{
			id: 'player_2',
			//image: Player2,
			name: 'Blue',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0,
			characterTitle: 'TEAM MEMBER BLUE',
			qty: 0
		},
		{
			id: 'player_5',
			//image: Player5,
			name: 'Red',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0,
			characterTitle: 'TEAM MEMBER RED',
			qty: 0
		},
		{
			id: 'player_3',
			//image: Player3,
			name: 'White',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0,
			characterTitle: 'TEAM MEMBER WHITE',
			qty: 0
		}
	],
};

export default initState;
