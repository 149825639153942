import { combineReducers } from 'redux'

import boardReducer from './reducers/board-reducer'
import startingKit from "./reducers/starting-kit-reducer";
import sessionReducer from "./reducers/session-reducer";
import impactReducer from "./reducers/impact-reducer";

export default combineReducers({
	board: boardReducer,
	startingKit: startingKit,
	session: sessionReducer,
	impact: impactReducer
})
