/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const ArrowIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '13'}px`}
      width={`${width || '13'}px`}
      viewBox="0 0 13 13"
      css={css`
        fill: ${color || Colors.white};
        max-width: ${width || '13'}px;
        max-height: ${height || '13'}px;
        height: ${height || '13'}px;
        width: ${width || '13'}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            fill: `${Colors.white}`,
          }}
          d="M 12.949219 6.292969 C 12.90625 6.203125 12.832031 6.128906 12.742188 6.085938 L 0.671875 0.0507812 C 0.441406 -0.0664062 0.164062 0.0273438 0.0507812 0.257812 C 0.015625 0.320312 0 0.390625 0 0.464844 L 0 12.535156 C 0 12.792969 0.207031 13 0.464844 13 C 0.535156 13 0.605469 12.984375 0.671875 12.949219 L 12.742188 6.914062 C 12.972656 6.800781 13.066406 6.523438 12.949219 6.292969 Z M 12.949219 6.292969 "
        />
      </g>
    </svg>
  );
};

export default ArrowIcon;

ArrowIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
