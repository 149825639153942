import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import language1 from './localization/1/translate.json'
import language2 from './localization/2/translate.json'
//ANCHOR_IMPORT_END

const resources = {
	"1": { translations: language1 },
	"2": { translations: language2 },
	//ANCHOR_RESOURCE_END
}

i18n
.use(LanguageDetector)
.use(XHR)
.use(initReactI18next)
.init({
	resources: resources,
	/* default language when load the website in browser */
	//lng: "de",
	/* When react i18next not finding any language to as default in borwser */
	fallbackLng: "1",
	/* debugger For Development environment */
	debug: true,
	ns: ["translations"],
	defaultNS: "translations",
	keySeparator: "__",
	nsSeparator: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ","
	},
	react: {
		wait: true,
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default'
	}
})

export default i18n;