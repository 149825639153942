const initState = {
	players: [
		{
			id: 'player_6',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0
		},
		{
			id: 'player_1',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0
		},
		{
			id: 'player_4',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0
		},
		{
			id: 'player_2',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0
		},
		{
			id: 'player_5',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0
		},
		{
			id: 'player_3',
			time: 0,
			amount: 0,
			skills: 0,
			motivation: 0,
			risk: 0
		}
	],
	team: {
		team: 0,
		teamLeader: 0,
		organization: 0
	}
};

export default initState;
