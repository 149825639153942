import React, { useEffect, useState } from "react"
import { useDispatch, useSelector  } from "react-redux"
import axios from 'axios';
import XLSX from 'xlsx';

import URLs from '../../utils/urls';

// Components
import _SelectionLogRow from './partials/_SelectionLogRow'
import Translate from '../../utils/Translate'

// CSS
import "../../css/selectionLog.css"

const headerRedStyle = {
	backgroundColor: "rgb(255, 0, 0)",
	height: "246px",
	verticalAlign: "bottom",
	padding: "0px !important"
}

const headerGreenStyle = {
	backgroundColor: "rgb(0, 255, 0)",
	height: "246px",
	verticalAlign: "bottom",
	padding: "0px !important"
}

const tableTDStyle = {
	height: "246px",
	verticalAlign: "bottom",
	padding: "0px !important"
}

const headerDivStyle = {
	transform: localStorage.isRTL === "1" ? "rotate(90deg)" : "rotate(-90deg)",
	whiteSpace: "nowrap",
	width: "30px",
	marginBottom: "5px",
	fontSize: 14
}

const SelectionLogView = () => {
	const dispatch = useDispatch()

	const sessionState = useSelector( state => state.session )
	const playersState = useSelector( state => state.startingKit.players )

	const [categoryList, setCategories] = React.useState([]);
	const [selectedCourses, setSelectedCourses] = useState([])

	const fetchCourses = async () => {
		try {
			const response = await axios.post(URLs.coursesLink, {
										sessionId: window.sessionId,
										languageId: (localStorage.langId) ? localStorage.langId : 1
									});

			setCategories(response.data.courses);
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		fetchCourses();
	}, []);

	useEffect(() => {
		if (sessionState.selectedCourses) {
			setSelectedCourses(sessionState.selectedCourses)
		}
	}, [sessionState])

	const exportFile = () => {
		var wb = XLSX.utils.table_to_book(document.getElementById('eventTeamTable'));
		XLSX.writeFile(wb, 'sample.xlsx');
	}

	const onClose = () => {
		dispatch({ type: "OPEN_SELECTION_LOG" })
	}

	return (
		<React.Fragment>
			<div className="log-actions">
				<button onClick={ exportFile }><Translate alias="Log" word="Export" /></button>
				<div onClick={ onClose } className="closeIcon">
					X
				</div>
			</div>
			<div style={{ width: "100%", height: "90%", overflow: "auto" }}>
				{
					(categoryList.length > 0) ?
						<table id="eventTeamTable">
							<tbody>
								<tr style={{ height: "30px" }}>
									<th></th>
									<th style={{ width: 200 }}></th>
									<th rowSpan={ 2 } style={ headerRedStyle }><div style={ headerDivStyle }><Translate alias="Log" word="Invest Time" /></div></th>
									<th rowSpan={ 2 } style={ headerRedStyle }><div style={ headerDivStyle }><Translate alias="Log" word="Invest Money" /></div></th>
									{ /*<th rowSpan={ 2 } style={ headerGreenStyle }><div style={ headerDivStyle }>EFFECT SKILLS & KNOWLEDGE</div></th>
									<th rowSpan={ 2 } style={ headerGreenStyle }><div style={ headerDivStyle }>EFFECT MOTIVATION</div></th>
									<th rowSpan={ 2 } style={ headerGreenStyle }><div style={ headerDivStyle }>EFFECT RETENTION RISK</div></th>*/ }
									{
										playersState.map( character => {
											return(
												<th className={ `row_${ character.id }` } colSpan={ 5 } key={ character.id }>{ character.name }</th>
											)
										})
									}
								</tr>
								<tr style={{ height: 100 }}>
									<td></td>
									<td></td>
									{
										playersState.map( character => {
											return(
												<React.Fragment key={ character.id }>
													<td className={ `row_${ character.id }` } style={ tableTDStyle }>
														<div style={ headerDivStyle }><Translate alias="Log" word="TIME" /></div>
													</td>
													<td className={ `row_${ character.id }` } style={ tableTDStyle }>
														<div style={ headerDivStyle }><Translate alias="Log" word="MONEY" /></div>
													</td>
													<td className={ `row_${ character.id }` } style={ tableTDStyle }>
														<div style={ headerDivStyle }><Translate alias="Log" word="EFFECT SKILLS & KNOWLEDGE" /></div>
													</td>
													<td className={ `row_${ character.id }` } style={ tableTDStyle }>
														<div style={ headerDivStyle }><Translate alias="Log" word="EFFECT MOTIVATION" /></div>
													</td>
													<td className={ `row_${ character.id }` } style={ tableTDStyle }>
														<div style={ headerDivStyle }><Translate alias="Log" word="EFFECT RETENTION RISK" /></div>
													</td>
												</React.Fragment>
											)
										})
									}
								</tr>
								{
									categoryList.map( (category) => {
										return (
											category.Courses.map( (course, index) => {
												return (
													<_SelectionLogRow
														key={ index }
														characters={ playersState }
														category={ (index === 0) ? category.name : "" }
														course={ course }
														coursesCount={ category.Courses.length }
														selectedCourses={ selectedCourses }
													/>
												)
											})
										)
									})
								}
							</tbody>
						</table>
						:
						null
				}
			</div>
		</React.Fragment>
	)
}

export default SelectionLogView
