const initState = {
  low: [
    {
      title: 'Low performing Star',
      players: [],
    },
    {
      title: 'Performing Star',
      players: [],
    },
    {
      title: 'Striving Star',
      players: [],
    },
    {
      title: 'Low performing Talent',
      players: [],
    },
    {
      title: 'Performing Talent',
      players: [],
    },
    {
      title: 'Top performing Talent',
      players: [],
    },
    {
      title: 'Low Performer',
      players: [],
    },
    {
      title: 'Consistent Performer ',
      players: [],
    },
    {
      title: 'Top Performer',
      players: [],
    },
  ],
  medium: [
    {
      title: 'Low performing Star',
      players: [],
    },
    {
      title: 'Performing Star',
      players: [],
    },
    {
      title: 'Striving Star',
      players: [],
    },
    {
      title: 'Low performing Talent',
      players: [],
    },
    {
      title: 'Performing Talent',
      players: [],
    },
    {
      title: 'Top performing Talent',
      players: [],
    },
    {
      title: 'Low Performer',
      players: [],
    },
    {
      title: 'Consistent Performer ',
      players: [],
    },
    {
      title: 'Top Performer',
      players: [],
    },
  ],
  high: [
    {
      title: 'Low performing Star',
      players: [],
    },
    {
      title: 'Performing Star',
      players: [],
    },
    {
      title: 'Striving Star',
      players: [],
    },
    {
      title: 'Low performing Talent',
      players: [],
    },
    {
      title: 'Performing Talent',
      players: [],
    },
    {
      title: 'Top performing Talent',
      players: [],
    },
    {
      title: 'Low Performer',
      players: [],
    },
    {
      title: 'Consistent Performer ',
      players: [],
    },
    {
      title: 'Top Performer',
      players: [],
    },
  ],
};

export default initState;
