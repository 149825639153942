import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import EventCard from './partials/EventCard'

import './../../css/eventOptions.css';

import mapDispatchToProps from '../../redux/dispatchers';
import * as RealtimeConnection from '../../utils/RealtimeConnection';

import URLs from '../../utils/urls';
import Translate from '../../utils/Translate'

const EventView = (props) => {
	const selectedOption = props.session.selectedEventOption

	const [event, setEvent] = React.useState({});

	const fetchOptions = async () => {
		try {
			const response = await axios.post(URLs.eventsLink, {
					sessionId: window.sessionId,
					languageId: (localStorage.langId) ? localStorage.langId : 1
				})
			if (response.data.event.id) {
				if (props.session.lastEventId !== response.data.event.id) {
					setEvent(response.data.event)
					props.updateCurrentEvent(response.data.event)

					if (props.session.eventsCurrentView === "")
						props.updateEventView('eventOptions')
				}else{
					props.openEvent();
				}
			}else{
				props.openEvent();
			}
		} catch (error) {
			console.log(error)
		}
	}

	React.useEffect(() => { fetchOptions() }, [])

	const selectOption = (option) => {
		RealtimeConnection.selectEventOption(option)
	}

	let probabilityValue = 0
	if (selectedOption.id) {
		probabilityValue = selectedOption.probability;

		const improvedCourses = selectedOption.AdjustmentPlus.map(course => course.id)
		const negativeCourses = selectedOption.AdjustmentMinus.map(course => course.id)

		if (props.session.currentEvent !== undefined && props.session.currentEvent.Character !== undefined) {
			const selectedPlayer = props.players.filter( player => player.id === props.session.currentEvent.Character.key)

			if (selectedPlayer && selectedPlayer.length > 0) {
				const roundKeys = Object.keys(props.session.selectedCourses)
				if (roundKeys.length > 0){
					roundKeys.forEach( key => {
						if (props.session.selectedCourses[key][selectedPlayer[0].id]) {
							props.session.selectedCourses[key][selectedPlayer[0].id].forEach( course => {
								if (improvedCourses.includes(course.id)) {
									probabilityValue += 10
								}
								if (negativeCourses.includes(course.id)) {
									probabilityValue -= 10
								}
							})
						}
					})
				}
			}
		}

		if (probabilityValue > 90) {
			probabilityValue = 90
		}
	}

	const sendSelectedCard = () => {
		if (props.session.selectedEventCard >= 0) {
			const card = props.session.eventOptionCards[props.session.selectedEventCard]
			if (card !== undefined) {
				const cardValue = (card === 1) ? "happy" : "sad"
				RealtimeConnection.updateSelectedEventData(props.session.roundNumber, props.session.currentEvent, props.session.selectedEventOption, cardValue)
				//RealtimeConnection.selectEventCard(props.session.selectedEventCard)
				props.UpdateLastEventId(props.session.currentEvent.id)
			}
		}
	}

	return (
		<>
			{props.session.eventsCurrentView === 'eventOptions' && event.id  && (
				<div>
					<div className="header">
						{event.name}
					</div>
					<div className="event-body">
						{event.description}
					</div>
					<div className="event-options">
						{
							event.EventOptions.map((option, index) => {
								let selected = selectedOption.id === option.id ? "selected" : ""
								return (
									<div className={`option ${selected}`} key={option.id} onClick={() => selectOption(option)}>
										<div className="option-title">{ option.name }</div>
										<div className="option-body">
											{option.decription}
										</div>
									</div>
								)
							})
						}
					</div>
					{
						(props.session.eventCardAppearanceStatus.applyDevelopmentSelection &&
							<button
								className="confirm-button"
								onClick={() => {
									if (selectedOption.id) {
										props.updateEventView('eventOption')
										RealtimeConnection.updateEventView('eventOption')
									}
								}}
							>
								{ <Translate alias="Board" word="Confirm" /> }
							</button>
						)
					}
				</div>
			)}
			{props.session.eventsCurrentView === 'eventOption' && selectedOption.id && (
				<div>
					<div className="header">
						{event.name}
					</div>
					<div className="event-body option-view">
						{ selectedOption.name }<br />
						{selectedOption.decription}
					</div>
					<div className="event-options">
						<div className="probability">
							{ <Translate alias="Board" word="Probability" /> }
							<div className="percentage">{selectedOption.probability}%</div>
						</div>
						<div className="pros">
							<div className="box-title">{ <Translate alias="Board" word="Adjustments" /> }</div>
							<ul className="g-list">
								{selectedOption.AdjustmentPlus.map(item =>(
									<li key={item.id}>{item.name}</li>
								))}
							</ul>
						</div>
						<div className="cons">
							<div className="box-title">{ <Translate alias="Board" word="Adjustments" /> }</div>
							<ul className="r-list">
								{selectedOption.AdjustmentMinus.map(item =>(
									<li key={item.id}>{item.name}</li>
								))}
							</ul>
						</div>
					</div>
					<button
						className="confirm-button"
						onClick={() => {
							props.updateEventView('eventCards')
							RealtimeConnection.updateEventView('eventCards', selectedOption, props.session.currentEvent)
						}}
					>
						{ <Translate alias="Board" word="Next" /> }
					</button>
				</div>
			)}
			{props.session.eventsCurrentView === 'eventCards' && (
			<div>
				<div className="cards-header">
					<div className="cards-title">
						{ <Translate alias="Board" word="Draw Your Card" /> }
					</div>
					<div className='option-wrapper-container'>
						<div className='selected-option'>
							{ selectedOption.name }
						</div>
						<div className="cards-probability">
							{ probabilityValue }%
						</div>
					</div>
				</div>
				<div className="card-grid">
					{
						props.session.eventOptionCards.map( (card, index) => {
							return (
								(card === 1) ?
									<EventCard happy key={ index } index={ index } />
									:
									<EventCard sad key={ index } index={ index } />
							)
						})
					}
				</div>
				{
					(props.session.eventCardAppearanceStatus.showResult && !props.session.eventCardAppearanceStatus.showCardResult &&
						<button
							className="confirm-button"
							onClick={ sendSelectedCard }
						>
							{ <Translate alias="Board" word="Apply" /> }
						</button>
					)
				}
			</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		players: state.startingKit.players,
		session: state.session
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EventView);
