import React, { useState, useEffect } from 'react';
import Avatar from "avataaars";

import URLs from "../../../utils/urls"

export default function Avater(props) {
	const [avatarType, setAvatarType] = useState("")
	const [pictureLink, setPictureLink] = useState(undefined)
	const [avatarOptions, setAvatarOptions] = useState(undefined)

	useEffect(() => {
		if (props.ImageSrc) {
			try {
				const isJSON = JSON.parse(props.ImageSrc)
				if (isJSON) {
					setAvatarOptions(isJSON)
					setAvatarType("Avatar")
				}
			} catch (e) {
				setPictureLink(URLs.backendURL + props.ImageSrc)
				setAvatarType("Image")
			}
		}
	}, [props.ImageSrc])
	
	return (
		<div className={ `avatarWrapper` } onClick={props.onClick}>
			{
				(avatarType === "Avatar" && avatarOptions && 
					<div className={ `${ (props.isCard) ? "avatar-wrapper": "avatar-wrapper-2" } ${ props.id }` }>
						<Avatar
							style={{ width: (props.isCard) ? "50px" : "30px", height: (props.isCard) ? "50px" : "30px" }}
							avatarStyle='Transparent'
							topType={ (avatarOptions.Top) ? avatarOptions.Top : "" }
							hairColor={ (avatarOptions.HairColor) ? avatarOptions.HairColor : "Black" }
							accessoriesType={ (avatarOptions.Accessories) ? avatarOptions.Accessories : "" }
							hatColor={ (avatarOptions.HatColor) ? avatarOptions.HatColor : "" }
							facialHairType={ (avatarOptions.FacialHair) ? avatarOptions.FacialHair : "" }
							facialHairColor={ (avatarOptions.FacialHairColor) ? avatarOptions.FacialHairColor : "" }
							clotheType={ (avatarOptions.Clothes) ? avatarOptions.Clothes : "" }
							clotheColor={ (avatarOptions.ColorFabric) ? avatarOptions.ColorFabric : "" }
							eyeType={ (avatarOptions.Eyes) ? avatarOptions.Eyes : "" }
							eyebrowType={ (avatarOptions.Eyebrow) ? avatarOptions.Eyebrow : "" }
							mouthType={ (avatarOptions.Mouth) ? avatarOptions.Mouth : "" }
							skinColor={ (avatarOptions.Skin) ? avatarOptions.Skin : "" }
						/>
					</div>
				)
			}
			{
				(avatarType === "Image" && pictureLink && 
					<img 
						src={ pictureLink } 
						style={{ width: (props.isCard) ? "50px" : "30px", height: (props.isCard) ? "50px" : "30px" }} 
					/>
				)
			}
		</div>
	);
}

/*
<img
	src={(props.ImageAlt === "Director") ? "/director.png" : props.ImageSrc}
	alt={props.ImageAlt ? props.ImageAlt : 'Avater'}
/>
*/