import initState from '../states/session-init-state';

const reducer = (state = initState, action) => {
	switch (action.type) {
		case 'UPDATE_ROUND':
			let roundNumber = state.roundNumber;
			roundNumber += 1;

			return {
				...state,
				roundNumber: roundNumber,
				lastEventId: 0
			}
		case 'RESTORE_ROUND':
			return {
				...state,
				roundNumber: action.round
			}
		case 'RESTORE_SESSION_DATA':
			return {
				...state,
				team: action.data.team,
				teamLead: action.data.teamLead,
				organization: action.data.organization,
				time: action.data.time,
				amount: action.data.amount
			}
		case 'RESTORE_SELECTED_COURSES':
			return {
				...state,
				selectedCourses: action.courses
			}
		case 'UPDATE_SESSION':
			switch (action.data.type.toUpperCase()) {
				case 'TEAM':
					return {
						...state,
						team: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				case 'TEAMLEAD':
					return {
						...state,
						teamLead: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				case 'ORGANIZATION':
					return {
						...state,
						organization: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				case 'SCORE':
					return {
						...state,
						score: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				case 'TIME':
					return {
						...state,
						time: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				/*case 'MONEY':
					return {
						...state,
						amount: (action.data.value) ? parseInt(action.data.value) : 0,
					};*/
				case 'AMOUNT':
					return {
						...state,
						amount: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				case 'SPENT':
					return {
						...state,
						spent: {
							...state.spent,
							time: action.data.time,
							money: action.data.money,
						}
					}
				default:
					break;
			}
			break;
		case 'UPDATE_SESSION_2':
			switch (action.data.type.toUpperCase()) {
				case 'TEAM':
					return {
						...state,
						team: state.team + parseInt(action.data.value),
					};
				case 'TEAMLEAD':
					return {
						...state,
						teamLead: state.teamLead + parseInt(action.data.value),
					};
				case 'ORGANIZATION':
					return {
						...state,
						organization: state.organization + parseInt(action.data.value),
					};
				case 'SCORE':
					return {
						...state,
						score: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				case 'TIME':
					let newTime = state.time + parseInt(action.data.value)
					if (newTime < 0) {
						newTime = 0
					}
					return {
						...state,
						time: newTime,
					};
				case 'AMOUNT':
					return {
						...state,
						amount: state.amount + parseInt(action.data.value)
					};
				/*case 'TIME':
					return {
						...state,
						time: (action.data.value) ? parseInt(action.data.value) : 0,
					};
				case 'AMOUNT':
					return {
						...state,
						amount: (action.data.value) ? parseInt(action.data.value) : 0,
					};*/
				case 'SPENT':
					return {
						...state,
						spent: {
							...state.spent,
							time: action.data.time,
							money: action.data.money,
						}
					}
				default:
					break;
			}
			break;
		case 'UPDATE_SESSION_TIME':
			switch (action.data.type.toUpperCase()) {
				case 'TIME':
					return {
						...state,
						time: state.time - action.data.value
					}
				case 'AMOUNT':
					return {
						...state,
						amount: state.amount - action.data.value
					}
				default:
					break;
			}
			break;
		case 'UPDATE_FILES':
			return {
				...state,
				files: action.value
			}
		case 'ADD_FILE': {
				let files = state.files;
				files.push(action.value);

				return {
					...state,
					files: files
				}
			}
		case 'REMOVE_FILE': {
				let listOfFiles = state.files.filter(fileObj => fileObj._id !== action.id)
			
				return {
					...state,
					files: listOfFiles
				}
				/*let listOfFiles = state.files.filter(fileObj => (parseInt(fileObj.fileId) !== parseInt(action.fileId)))

				return {
					...state,
					files: listOfFiles
				}*/
			}
		case 'CHANGE_TEAM_NAME':
			return {
				...state,
				teamName: action.name
			}
		case 'OPEN_DEVELOPMENT' :
			if (state.eventCardAppearanceStatus.activateDevelopmentOptions) {
				return {
					...state,
					development: !state.development, //true
					event: false
				}
			}else{
				return {
					...state,
					development: false
				}
			}
		case 'OPEN_SELECTION_LOG':
			return {
				...state,
				selectionLog: !state.selectionLog, //true
			}
		case 'OPEN_EVENT' :
			if (state.event && state.currentEvent.id === state.lastEventId) {
				return {
					...state,
					development: false,
					event: !state.event,
					currentEvent: {},
					eventsCurrentView: "",
					selectedEventOption: {},
					eventOptionCards: [],
					selectedEventCard: undefined
				}
			}else{
				return {
					...state,
					development: false,
					event: !state.event //true
				}
			}
		case 'SELECT_COURSE': {
			let activeSelectedCourses = { ...state.activeSelectedCourses }
			if (activeSelectedCourses[action.playerId] === undefined) {
				activeSelectedCourses[action.playerId] = [action.course]

				return {
					...state,
					activeSelectedCourses: activeSelectedCourses
				}
			}else{
				//let selectedCourses = [ ...state.activeSelectedCourses[action.playerId] ];
				//selectedCourses.push(action.course)

				return {
					...state,
					activeSelectedCourses: {
						...state.activeSelectedCourses,
						[action.playerId]: [
							...state.activeSelectedCourses[action.playerId],
							action.course
						]
					}
				}
			}



			/*return {
				...state,
				activeSelectedCourses: {
					...state.activeSelectedCourses,
					[action.playerId]: [
						...state.activeSelectedCourses[action.playerId],
						action.course
					]
				}
			}*/

			/*let selectedCourses = { ...state.selectedCourses }
			if (selectedCourses[state.roundNumber] === undefined) {
				selectedCourses[state.roundNumber] = {}
			}

			if (selectedCourses[state.roundNumber][action.playerId]) {
				let selectedCourses = [ ...state.selectedCourses[state.roundNumber][action.playerId] ];
				selectedCourses.push(action.course)

				return {
					...state,
					selectedCourses: {
						...state.selectedCourses,
						[state.roundNumber]: {
							...state.selectedCourses[state.roundNumber],
							[action.playerId]: selectedCourses
						}
					}
				}
			}else{
				selectedCourses[state.roundNumber][action.playerId] = [action.course]
				return {
					...state,
					selectedCourses: selectedCourses
				}
			}*/
		}
		case 'DESELECT_COURSE': {
			let activeSelectedCourses = [ ...state.activeSelectedCourses[action.playerId] ];
			activeSelectedCourses = activeSelectedCourses.filter(course => course.id !== action.course.id)

			return {
				...state,
				activeSelectedCourses: {
					...state.activeSelectedCourses,
					[action.playerId]: state.activeSelectedCourses[action.playerId].filter(course => course.id !== action.course.id)
				}
			}

			/*let selectedCourses = [ ...state.selectedCourses[state.roundNumber][action.playerId] ];
			selectedCourses = selectedCourses.filter(course => course.id !== action.course.id)

			return {
				...state,
				selectedCourses: {
					...state.selectedCourses,
					[state.roundNumber]: {
						...state.selectedCourses[state.roundNumber],
						[action.playerId]: selectedCourses
					}
				}
			}*/
		}
		case "COURSES_SUBMITTED": {
			let selectedCourses = { ...state.selectedCourses }
			if (selectedCourses[state.roundNumber] === undefined) {
				selectedCourses[state.roundNumber] = {}
			}

			const PlayersIds = Object.keys(action.courses)
			PlayersIds.forEach( playerId => {
				if (selectedCourses[state.roundNumber][playerId]) {
					selectedCourses[state.roundNumber][playerId] = [
						...selectedCourses[state.roundNumber][playerId],
						...action.courses[playerId]
					]
				}else{
					selectedCourses[state.roundNumber][playerId] = action.courses[playerId]
				}
			})

			return {
				...state,
				selectedCourses: selectedCourses,
				activeSelectedCourses: []
			}
		}
		case 'PURCHASE_COURSE_STATUS': {
			return {
				...state,
				allowCoursePurachse: action.status
			}
		}
		case 'UPDATE_CURRENT_EVENT': {
			return {
				...state,
				currentEvent: action.event
			}
		}
		case 'UPDATE_LAST_EVENT': {
			return {
				...state,
				lastEventId: action.event
			}
		}
		case 'SELECT_EVENT_OPTION':
			return {
				...state,
				selectedEventOption: action.option
			}
		case 'SELECT_CARD':
			return {
				...state,
				selectedEventCard: action.card
			}
		case 'UPDATE_EVENT_VIEW':
			return {
				...state,
				eventsCurrentView: action.view
			}
		case 'UPDATE_EVENT_CARDS':
			return {
				...state,
				eventOptionCards: action.cards
			}
		case 'OPEN_PROFILE' :
			return {
				...state,
				playerProfile: true,
				selectedPlayer: action.player
			}
		case 'CLOSE_PROFILE' :
			return {
				...state,
				playerProfile: false,
				selectedPlayer: {}
			}
		case 'RESTORE_LOCALIZATION':
			return {
				...state,
				localizations: action.localizations
			}
		case 'UPDATE_EVENT_CARD_STATUS':
			return {
				...state,
				eventCardAppearanceStatus: {
					...state.eventCardAppearanceStatus,
					[action.data.type]: action.data.status
				}
			}
		case 'RESTORE_EVENT_CARD_STATUS':
			return {
				...state,
				eventCardAppearanceStatus: {
					...state.eventCardAppearanceStatus,
					...action.data
				}
			}
		case 'UPDATE_WHITE_CHARACTER_STATUS': {
			return {
				...state,
				whiteCharacterStatus: action.status
			}
		}
		case "UPDATE_COUNTER": {
			return {
				...state,
				timer: action.value
			}
		}
		case "APPLY_STARTING_POSITION": {
			return {
				...state,
				team: action.data.teamScore ? action.data.teamScore.team : 0,
				teamLead: action.data.teamScore ? action.data.teamScore.teamLeader : 0,
				organization: action.data.teamScore ? action.data.teamScore.organization : 0,	
				time: action.data.teamScore ? action.data.teamScore.time : 0,
				amount: action.data.teamScore ? action.data.teamScore.money : 0
			}
		}
		default:
			break;
	}
	return state;
};

export default reducer;
