import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import mapDispatchToProps from '../../redux/dispatchers';

import { EventIcon, GoalIcon } from 'images';
import { Colors } from 'utils';

import GlobeIcon from '../../images/languageIcon.png';
import i18n from '../../i18n'
import Translate from '../../utils/Translate'

const EventsBarWrapper = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  background: ${Colors.white};
  text-align: center;
  transform: translateY(-50%);
  padding: 60px 15px;
  border-radius: 12px 0 0 12px;
  .item {
	display: block;
	cursor: pointer;
	span {
	  display: block;
	  font-weight: 700;
	  font-size: 10px;
	}
	& + .item {
	  margin-top: 30px;
	  cursor: pointer;
	}
  }
  &::before {
	content: '';
	width: 5px;
	height: 120px;
	display: inline-block;
	background: ${Colors.ghostGray};
	position: absolute;
	top: 50%;
	right: 110%;
	transform: translateY(-50%);
	border-radius: 100px;
  }
`;

let developmentFile = "";
let eventFile = "";

class EventsBar extends Component {

	state = {
		isOpen: false
	}

	onDevelopmentClick = () => {
		// if (developmentFile !== "") {
		// 	window.open(developmentFile, '_blank');
		// }
		if (this.props.canEdit) {
			this.props.openDevelopment()
		}
	}

	onEventClick = () => {
		// if (eventFile !== "") {
		// 	window.open(eventFile, '_blank');
		// }
		if (this.props.canEdit) {
			this.props.openEvent()
		}
	}

	onLanguageClick = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	onLanguageChange = (lang, isRTL) => {
		if (localStorage.langId !== lang) {
			localStorage.langId = lang
			localStorage.isRTL = (isRTL) ? 1 : 0
			i18n.changeLanguage(`${ lang }`)

			window.location.reload()
		}
	}

	render = () => {
		// developmentFile = "";
		// eventFile = "";
		// if (this.props.files) {
		// 	this.props.files.forEach( file => {
		// 		if (file.type.toLowerCase() === "development") {
		// 			developmentFile = file.file;
		// 		}else if (file.type.toLowerCase() === "event") {
		// 			eventFile = file.file;
		// 		}
		// 	});
		// }

		const devColor = this.props.session.development ? "#2F7DAD" : ""
		const evColor = this.props.session.event ? "#2F7DAD" : ""
		
		return (
			<EventsBarWrapper>
				<div style={{ position: "relative" }}>
					<h1 className="visually-hidden">Development And Events Bar</h1>
					<div className="item" color={"#2F7DAD"} onClick={ this.onLanguageClick.bind(this) }>
						<img src={ GlobeIcon } style={{ width: 40 }} />
						<span>{ <Translate alias="Board" word="Languages" /> }</span>
					</div>
					<div className="item" color={"#2F7DAD"} onClick={ this.onDevelopmentClick }>
						<GoalIcon color={devColor} />
						<span>{ <Translate alias="Board" word="Development" /> }</span>
					</div>
					<div className="item" onClick={ this.onEventClick }>
						<EventIcon color={evColor} />
						<span>{ <Translate alias="Board" word="Event" /> }</span>
					</div>
					{
						(this.state.isOpen) ?
							<div className={ `languagesList ${ (localStorage.isRTL === "1") ? "rtl" : "" }` }>
								{
									(this.props.languages.length > 0) ?
										<React.Fragment>
											{
												this.props.languages.map( lang => {
													return (
														<div key={ lang.id } className="language" onClick={ this.onLanguageChange.bind(this, lang.id, lang.isRTL) }>
															{ lang.name }
														</div>
													);
												})
											}
										</React.Fragment>
										:
										<div key={ 1 } className="language" onClick={ this.onLanguageChange.bind(this, 1, false) }>
											English
										</div>
								}
							</div>
							:
							null
					}
				</div>
			</EventsBarWrapper>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		session: state.session
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsBar);
