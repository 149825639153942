import initState from '../states/impact-init-state';

const reducer = (state = initState, action) => {
	switch(action.type) {
		case 'IMPACT_UPDATE_CHARACTER':
			switch (action.data.type.toUpperCase()) {
				case 'TIME':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, time: action.data.value} : player
						)
					}
				case 'AMOUNT':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, amount: action.data.value} : player
						)
					};
				case 'SKILLS':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, skills: action.data.value} : player
						)
					};
				case 'MOTIVATION':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, motivation: action.data.value} : player
						)
					};
				case 'RISK':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, risk: action.data.value} : player
						)
					};
				default:
					break;
			}
			break;
		case 'IMPACT_UPDATE_SESSION_CHARACTER':
			return {
				...state,
				players: state.players.map( (player) => {
					if (action.data[player.id]) {
						player.time = action.data[player.id].time
						player.amount = action.data[player.id].amount
						player.skills = action.data[player.id].skills;
						player.motivation = action.data[player.id].motivation;
						player.risk = action.data[player.id].risk;
					}
					return player
				})
			}
		case 'IMPACT_UPDATE_HEADER_VALUE':
			return {
				...state,
				team: {
					...state.team,
					[action.fieldType]: action.value
				}
			}
		default:
			break;
	}

	return state;
};

export default reducer;
