import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Colors } from 'utils';

const HeaderItemWrapper = styled.div`
  & > div {
    display: flex;
    align-items: flex-start;
  }
  .image-wrapper {
    margin-right: 10px;
  }
  .details-wrapper {
    p {
      line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1')};
      margin: 0;
      font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
      font-weight: 700;
      &.number {
        font-size: 21px;
        font-weight: 700;
        margin-top: 5px;
        color: ${(props) =>
          props.numberColor ? props.numberColor : Colors.primaryColor};
      }
      &.player-color {
        text-transform: uppercase;
        color: #8f92a1;
        font-size: 11px;
        line-height: 7px;
        font-weight: 400;
      }
    }
  }
`;

export default function HeaderItem(props) {
  const { number, title, playerTitle, image } = props;
  return (
    <HeaderItemWrapper {...props} className="header-item">
      <div>
        <div className="image-wrapper">{image}</div>
        <div className="details-wrapper">
          <p>{title}</p>
          {number && <p className="number">{number}</p>}
        </div>
		{
			(props.impact !== undefined && props.impact !== 0 &&
				<div className="impact-wrapper">
					<div className={ `impact ${ (props.impact > 0) ? "up" : "down" }` }>
						{ props.impact }
						<i class={ `arrow ${ (props.impact > 0) ? "up" : "down" }` }></i>
					</div>
				</div>
			)
		}
      </div>
    </HeaderItemWrapper>
  );
}
//{playerTitle && <p className="player-color">{playerTitle}</p>}

HeaderItem.propTypes = {
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    fontSize: PropTypes.string,
    lineHeight: PropTypes.string,
    playerTitle: PropTypes.string,
    number: PropTypes.number,
    numberColor: PropTypes.string,
};
