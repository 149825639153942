import React from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';

// Components
import Avater from '../BoardElements/Character/Avater';

export default function DraggablePlayer(props) {

	const [{ isDragging }, drag, preview] = useDrag({
		item: {
			type: 'Character',
			id: props.id,
			image: props.image,
			name: props.name,
			qty: props.qty,
			index: props.index,
			characterIndex: props.characterIndex,
			cardIndex: (props.cardIndex >= 0) ? props.cardIndex : -1,
			cardCategory: (props.cardCategory) ? props.cardCategory : ""
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			let droppedItem = monitor.getDropResult();
			if (droppedItem !== null && droppedItem.type !== undefined) {
				if (monitor.didDrop() && props.removeCharacter !== undefined) {
					if (props.cardIndex === droppedItem.cardIndex || droppedItem.cardIndex === -1) {
						props.removeCharacter(props.cardIndex, droppedItem);
					}
				}
			}
		}
	});

	const canDrag = (props.qty > 0 && props.canEdit) ? drag : null;
	return (
		<>
			<DragPreviewImage connect={preview} src={props.image} />
			<div style={{ width: '100%', opacity: (isDragging) ? 0.5 : 1 }} ref={ canDrag }>
				<Avater id={ props.id } isCard={props.isCard} ImageSrc={props.image} ImageAlt={props.name} onClick={props.onImgClick} />
			</div>
		</>
	);
}
