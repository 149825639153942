import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Colors } from 'utils';

const RatingWrapper = styled.div`
  position: relative;
  &::before {
    content: 'Low';
    font-size: 8px;
    line-height: 20px;
    position: absolute;
    top: -15px;
    left: 0;
  }
  &::after {
    content: 'High';
    font-size: 8px;
    line-height: 20px;
    position: absolute;
    top: -15px;
    right: 0;
  }
  span {
    display: inline-block;
    width: 43px;
    height: 6px;
    border-radius: 3px;
    background: ${Colors.athensGray};
    &.checked {
      background: ${Colors.blue};
      &:nth-of-type(1) {
        opacity: 0.2;
      }
      &:nth-of-type(2) {
        opacity: 0.4;
      }
      &:nth-of-type(3) {
        opacity: 0.6;
      }
      &:nth-of-type(4) {
        opacity: 0.8;
      }
      &:nth-of-type(5) {
        opacity: 1;
      }
    }
    & + span {
      margin-left: 2px;
    }
  }
`;
export default function Rating(props) {
  const { rating } = props;
  return (
    <RatingWrapper>
      <span className={rating >= 1 ? 'checked' : ''}></span>
      <span className={rating >= 2 ? 'checked' : ''}></span>
      <span className={rating >= 3 ? 'checked' : ''}></span>
      <span className={rating >= 4 ? 'checked' : ''}></span>
      <span className={rating >= 5 ? 'checked' : ''}></span>
    </RatingWrapper>
  );
}

Rating.propTypes = {
  rating: PropTypes.number,
};
