import initState from '../states/players-init-state';

const reducer = (state = initState, action) => {
	switch(action.type) {
		case "RESTORE_CHARACTERS":
			let newCharacters = [];
			state.players.forEach( player => {
				action.data.forEach( newPlayer => {
					if (player.id === newPlayer.id) {
						newCharacters.push({
							...player,
							...newPlayer
						})
					}
				})
			})
			return {
				...state,
				players: newCharacters
			}
		case 'UPDATE_CHARACTER':
			switch (action.data.type.toUpperCase()) {
				case 'TIME':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, time: action.data.value} : player
						)
					}
				case 'AMOUNT':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, amount: action.data.value} : player
						)
					};
				case 'SKILLS':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, skills: action.data.value} : player
						)
					};
				case 'MOTIVATION':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, motivation: action.data.value} : player
						)
					};
				case 'RISK':
					return {
						...state,
						players: state.players.map( (player, index) =>
							(index === action.data.index) ?
								{...player, risk: action.data.value} : player
						)
					};
				default:
					break;
			}
			break;
		case 'DECREASE_CHARACTER':
			return {
				...state,
				players: state.players.map( (player) =>
					(player.id === action.data.id) ?
						{...player, qty: 0} : player
				)
			};
		case 'INCREASE_CHARACTER':
			return {
				...state,
				players: state.players.map( (player) =>
					(player.id === action.data.id) ?
						{...player, qty: 1} : player
				)
			};
		case 'UPDATE_SESSION_CHARACTER':
			return {
				...state,
				players: state.players.map( (player) => {
					if (action.data[player.id]) {
						player.time += action.data[player.id].time
						player.amount += action.data[player.id].amount
						player.skills += action.data[player.id].skills;
						player.motivation += action.data[player.id].motivation;
						player.risk += action.data[player.id].risk;

						if (player.risk <= 0) {
							player.risk = 1
						}else if (player.risk > 5) {
							player.risk = 5
						}
					}
					return player
				})
			}
		case 'UPDATE_WHITE_CHARACTER_DATA':
			return {
				...state,
				players: state.players.map( (player) => {
					if (player.id === "player_3") {
						player.name = action.data.name
						player.image = action.data.picture ? action.data.picture : action.data.avatar
					}
					return player
				})
			}
		case "APPLY_STARTING_POSITION": {
			const newCharacterValues = {}
			for(let i = 0; i < action.data.characters.length; i++) {
				const character = action.data.characters[i]
				newCharacterValues[character.Character.key] = character
			}
			
			return {
				...state,
				players: state.players.map( (player) => {
					if (newCharacterValues[player.id] !== undefined) {
						player.time = newCharacterValues[player.id].time
						player.amount = newCharacterValues[player.id].money
						player.skills = newCharacterValues[player.id].skills
						player.motivation = newCharacterValues[player.id].motivation
						player.risk = newCharacterValues[player.id].risk
					}
					return player
				})
			}
		}
		default:
			break;
	}

	return state;
};

export default reducer;
