import React, { Component } from 'react';
import { connect } from "react-redux";

// Dispatcher
import mapDispatchToProps from "../redux/dispatchers";
import * as RealtimeConnection from "../utils/RealtimeConnection";

// Components
import Card from './BoardElements/Card';

class Cards extends Component {

	appendCharacter = (index, character) => {
		this.props.appendCharacter(this.props.category, index, character);
		//this.props.addContainer("LeftBlock", this.state.id, coins, window.playerId, window.playerName);

		// Update Socket
		RealtimeConnection.addCharacter({
			id: character.id,
			image: character.image,
			name: character.name,
			qty: character.qty,
			category: this.props.category,
			cardIndex: index
		})
	}

	removeCharacter = (index, character) => {
		this.props.removeCharacter(this.props.category, index, character);

		// Update Socket
		RealtimeConnection.removeCharacter({
			id: character.id,
			image: character.image,
			name: character.name,
			qty: character.qty,
			category: this.props.category,
			cardIndex: index,
			characterIndex: character.characterIndex
		})
		//this.props.removeContainer("LeftBlock", this.state.id);
	}

	render() {
		const { cards, category } = this.props;
		return (
			<div className="cardsWrapper cards">
				{
					cards.map( (card, index) => (
						<Card
							canEdit={ this.props.canEdit }
							title={ (this.props.localizations.GridBox && this.props.localizations.GridBox[card.title.toLowerCase()]) ? this.props.localizations.GridBox[card.title.toLowerCase()] : card.title }
							key={ index }
							players={ card.players }
							cardIndex={ index }
							category={ category }
							appendCharacter={ this.appendCharacter }
							removeCharacter={ this.removeCharacter }
						/>
					))
				}
				<div className="numbers">
					<p> { /*&lt; 100%*/ }</p>
					<p> { /*100%*/ }</p>
					<p> { /*&gt; 100%*/ }</p>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		localizations: state.session.localizations
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Cards);