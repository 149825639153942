const sessionState = {
	roundNumber: 0,
	team: 0,
	teamLead: 0,
	organization: 0,
	score: 0,
	time: 0,
	amount: 0,
	files: [],
	teamName: "",
	selectionLog: false,
	development: false,
	event: false,
	spent : {
		time: 0,
		money: 0
	},
	activeSelectedCourses: {},
	selectedCourses: {},
	playerProfile: false,
	selectedPlayer: {},
	allowCoursePurachse: true,
	lastEventId: 0,
	currentEvent: {},
	eventsCurrentView: "",
	selectedEventOption: {},
	eventOptionCards: [],
	selectedEventCard: undefined,
	eventCardAppearanceStatus: {
		activateDevelopmentOptions: false,
		allowDevelopmentSelection: false,
		applyDevelopmentSelection: false,
		allowSelection: false,
		showResult: false,
		showCardResult: false
	},
	whiteCharacterStatus: false,
	localizations: {},
	timer: {},
};

export default sessionState;
