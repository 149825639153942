/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const ModavtionIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '26'}px`}
      width={`${width || '21'}px`}
      viewBox="0 0 21 26"
      css={css`
        fill: ${color || Colors.black};
        max-width: ${width || '21'}px;
        max-height: ${height || '26'}px;
        height: ${height || '26'}px;
        width: ${width || '21'}px;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4394 11.0919C11.2461 10.6753 10.2276 8.57117 9.13845 8.05508L9.02602 8.00199L8.90266 7.99145C8.85464 7.98754 7.82793 7.91142 6.02084 8.39354C6.05754 7.53392 5.9982 6.64657 5.76826 5.83496C8.32762 5.43482 9.90868 3.30215 9.98246 3.20026C10.2026 2.89498 10.1335 2.46946 9.82865 2.2485C9.52376 2.02754 9.09707 2.09508 8.87533 2.39958C8.85933 2.42144 7.28803 4.53772 4.94455 4.53772C4.91917 4.53772 4.89341 4.53772 4.86803 4.53693C4.3375 4.4245 2.24582 4.23361 0.113539 7.45467C-0.094536 7.76932 -0.008261 8.19328 0.306389 8.40135C0.422333 8.47865 0.553112 8.51495 0.68272 8.51495C0.904458 8.51495 1.12151 8.4076 1.25307 8.20889C2.64674 6.10355 3.84015 5.84941 4.32774 5.84589C5.14677 7.91532 4.25357 11.1126 3.97444 11.9995C3.47397 12.1932 2.05648 12.4293 0.879083 12.548C0.503924 12.5867 0.230264 12.9216 0.268131 13.2968C0.304047 13.6489 0.600739 13.9112 0.947401 13.9112C0.970043 13.9112 0.993466 13.9101 1.0165 13.9077C2.24543 13.7836 4.39606 13.4978 4.991 12.9774L5.1253 12.8599L5.18503 12.6921C5.21938 12.5953 5.63904 11.3944 5.87405 9.85671C7.35166 9.40659 8.3163 9.35272 8.65359 9.35077C9.09356 9.66815 9.77634 10.7554 10.1999 11.6669C10.3159 11.916 10.5626 12.0624 10.8202 12.0624C10.9167 12.0624 11.0142 12.0417 11.1072 11.9987C11.4495 11.8403 11.5983 11.4339 11.4394 11.0919Z"
        fill={`${color || Colors.black}`}
      />
      <mask
        id="mask0"
        masktype="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="4"
        height="4"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.22485 0.390541H5.21655V3.38248H2.22485V0.390541Z"
          fill={`${Colors.white}`}
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.21627 1.91628C5.19948 2.74195 4.51631 3.39857 3.69064 3.38218C2.86537 3.36578 2.20913 2.68222 2.22514 1.85655C2.24193 1.0305 2.92432 0.374262 3.75076 0.390659C4.57643 0.407055 5.23227 1.09023 5.21627 1.91628Z"
          fill={`${color || Colors.black}`}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.2411 25.0855V20.3782C2.2411 20.1097 2.45972 19.8903 2.72908 19.8903H4.28594C4.5553 19.8903 4.77392 20.1097 4.77392 20.3782V25.0855C4.77392 25.0921 4.77079 25.0968 4.7704 25.1031H6.1387C6.1387 25.0968 6.14026 25.0917 6.14026 25.0855V20.3782C6.14026 19.3562 5.30835 18.5239 4.28594 18.5239H2.72908C1.70667 18.5239 0.874756 19.3562 0.874756 20.3782V25.0855C0.874756 25.0917 0.876317 25.0968 0.876317 25.1031H2.24462C2.24423 25.0968 2.2411 25.0921 2.2411 25.0855Z"
        fill={`${color || Colors.black}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40285 25.0855V16.0457C9.40285 15.7764 9.62185 15.5578 9.89083 15.5578H11.4403C11.7092 15.5578 11.9282 15.7764 11.9282 16.0457V25.0855C11.9282 25.0921 11.9247 25.0968 11.9247 25.1031H13.2926C13.2926 25.0968 13.2946 25.0917 13.2946 25.0855V16.0457C13.2946 15.0233 12.4627 14.1914 11.4403 14.1914H9.89083C8.86841 14.1914 8.0365 15.0233 8.0365 16.0457V25.0855C8.0365 25.0917 8.03806 25.0968 8.03806 25.1031H9.40636C9.40597 25.0968 9.40285 25.0921 9.40285 25.0855Z"
        fill={`${color || Colors.black}`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5711 25.0855V9.97995C16.5711 9.71097 16.7901 9.49197 17.059 9.49197H18.6093C18.8782 9.49197 19.0972 9.71097 19.0972 9.97995V25.0855C19.0972 25.0921 19.0937 25.0968 19.0937 25.1031H20.4616C20.4616 25.0968 20.4636 25.0917 20.4636 25.0855V9.97995C20.4636 8.95753 19.6317 8.12562 18.6093 8.12562H17.059C16.0366 8.12562 15.2047 8.95753 15.2047 9.97995V25.0855C15.2047 25.0917 15.2063 25.0968 15.2063 25.1031H16.5746C16.5742 25.0968 16.5711 25.0921 16.5711 25.0855Z"
        fill={`${color || Colors.black}`}
      />
    </svg>
  );
};

export default ModavtionIcon;

ModavtionIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
