import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainLogo, HappyFace, SadFace } from 'images';

import mapDispatchToProps from '../../../redux/dispatchers';
import * as RealtimeConnection from '../../../utils/RealtimeConnection';

//{ session, index, happy, sad, selectedCard, updateCurrentEvent }
const EventCard = (props) => {

	const [image, setImage] = useState(MainLogo)
	const [allowSelection, setAllowSelection] = useState(false)
	const [isSelected, setIsSelected] = useState("")
	const [showOthers, setShowOthers] = useState("")
	const [isClicked, setIsClicked] = useState("")

	useEffect(() => {
		setAllowSelection(props.session.eventCardAppearanceStatus.allowSelection)
	}, [props.session.eventCardAppearanceStatus.allowSelection])

	useEffect(() => {
		if (props.session.eventCardAppearanceStatus.showResult && props.session.eventCardAppearanceStatus.showCardResult && (props.session.selectedEventCard !== undefined)) {
			const clicked = (props.index === props.session.selectedEventCard) ? true : false
			setIsSelected(clicked ? "selected" : "")
			setShowOthers("show")

			if(clicked || props.session.selectedEventCard !== undefined){
				props.happy && setImage(HappyFace)
				props.sad && setImage(SadFace)
			}
		}else{
			setShowOthers("")
			const clicked = (props.index === props.session.selectedEventCard) ? true : false
			if (clicked) {
				setIsClicked("clicked")
			}else{
				setIsClicked("")
			}
		}
	}, [props.session])
	
	const selectCard = () => {
		if (allowSelection) {
			RealtimeConnection.selectEventCard(props.index)
		}
	}

	return (
		<div className={`card ${isClicked} ${isSelected} ${showOthers}`} onClick={() => selectCard()}>
			<img src={image} alt="" />{ (props.index + 1) }
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		session: state.session
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(EventCard);
