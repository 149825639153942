/** @jsx jsx */
import { jsx, Global, css } from '@emotion/core';
import { fontFace } from './FontGenerationHelper';
import { Colors } from './Theme';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        ${fontFace('Poppins', 'Poppins-Thin', 100)};
        ${fontFace('Poppins', 'Poppins-ExtraLight', 200)};
        ${fontFace('Poppins', 'Poppins-Light', 300)};
        ${fontFace('Poppins', 'Poppins-Regular', 400)};
        ${fontFace('Poppins', 'Poppins-Medium', 500)};
        ${fontFace('Poppins', 'Poppins-SemiBold', 600)};
        ${fontFace('Poppins', 'Poppins-Bold', 700)};
        ${fontFace('Poppins', 'Poppins-ExtraBold', 800)};
        ${fontFace('Poppins', 'Poppins-Black', 900)};
        ${fontFace('Cairo', 'Cairo-ExtraLight', 200)};
        ${fontFace('Cairo', 'Cairo-Light', 300)};
        ${fontFace('Cairo', 'Cairo-Regular', 400)};
        ${fontFace('Cairo', 'Cairo-SemiBold', 600)};
        ${fontFace('Cairo', 'Cairo-Bold', 700)};
        ${fontFace('Cairo', 'Cairo-Black', 900)};
        html {
          box-sizing: border-box;
          img {
            max-width: 100%;
          }
        }
        *,
        ::before,
        ::after {
          box-sizing: inherit;
        }
        html,
        body {
          scroll-behavior: smooth;
          padding: 0;
          margin: 0;
        }
        body {
          background: ${Colors.gray};
          font-family: Poppins, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.6;
        }
        button,
        a {
          cursor: pointer;
          outline: none;
          color: ${Colors.black};
          text-decoration: none;
          &:focus {
            outline: none;
          }
          &:visited {
            color: inherit;
          }
        }
        .visually-hidden {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        svg:not(:root) {
          overflow: initial;
        }
      `}
    />
  );
};

export default GlobalStyles;
