// Add Character
export const addCharacter = (props) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		...props
	}

	window.socket.emit('add-character', dataObj);
}

// Remove Character
export const removeCharacter = (props) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		...props
	}

	window.socket.emit('remove-character', dataObj);
}

// Decrease Character
export const decreaseCharacter = (props) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		...props
	}

	window.socket.emit('decrease-character', dataObj);
}

// Increase Character
export const increaseCharacter = (props) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		...props
	}

	console.log("increase-character")
	console.log(dataObj)
	window.socket.emit('increase-character', dataObj);
}

// Update Team Name
export const changeTeamName = (options) => {
	window.socket.emit("change-team", {
		sessionId: window.sessionId,
		teamId: window.teamId,
		value: options.value
	});
}

// Select Character Course
export const selectCharacterCourse = (characterId, course) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		characterId: characterId,
		course: course
	}

	window.socket.emit('select-player-project', dataObj);
}

// DeSelect Character Course
export const deselectCharacterCourse = (characterId, course) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		characterId: characterId,
		course: course
	}

	window.socket.emit('deselect-player-project', dataObj);
}

// Confirm Courses
export const confirmCourses = (roundNumber, courses) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		roundNumber: roundNumber,
		courses: courses
	}

	window.socket.emit('confirm-courses', dataObj);
}

// Select Event Option
export const selectEventOption = (option) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		option: option
	}

	window.socket.emit('select-event-option', dataObj);
}

// Update event view
export const updateEventView = (view, selectedOption, currentEvent) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		view: view,
		currentEvent: currentEvent,
		selectedOption: selectedOption
	}

	window.socket.emit('select-event-view', dataObj);
}

// Update selected event result
export const updateSelectedEventData = (roundNumber, event, option, card) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		roundNumber: roundNumber,
		selectedEvent: event,
		selectedOption: option,
		selectedCard: card
	}

	window.socket.emit('update-selected-event-data', dataObj);
}

// select event card
export const selectEventCard = (index) => {
	let dataObj = {
		teamId: window.teamId,
		sessionId: window.sessionId,
		playerId: window.playerId,
		playerName: window.playerName,
		selectedCardIndex: index
	}

	window.socket.emit('event-card-select', dataObj);
}
