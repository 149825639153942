/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const MoneyIcon = ({ bgColor, color, width, height }) => {
  return (
    <svg
      height={`${height || '41'}px`}
      width={`${width || '41'}px`}
      viewBox="0 0 41 41"
      css={css`
        fill: ${bgColor || Colors.white};
        max-width: ${width || '41'}px;
        max-height: ${height || '41'}px;
        height: ${height || '41'}px;
        width: ${width || '41'}px;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 1C31.2526 1 40 9.7481 40 20.4995C40 31.2528 31.2526 40 20.5 40C9.7483 40 1 31.2528 1 20.4995C1 9.7481 9.7483 1 20.5 1Z"
        stroke={`${color || Colors.green}`}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8702 29.7979L18.8685 27.5997L18.6674 27.5791C15.7451 27.2831 13.512 26.0197 12.8851 24.3335L15.3314 23.734C15.7457 24.2592 16.9554 24.8723 18.616 25.0934L18.8697 25.1277V21.2821L18.6817 21.2512C16.4097 20.8867 15.1302 20.3364 14.5691 20.0393L14.4754 19.989C13.3137 19.2044 12.7 18.2193 12.7 17.1388C12.7011 15.0051 15.1554 13.2663 18.668 12.912L18.8697 12.8932V10.7498H21.3257L21.3262 12.9074L21.5251 12.9309C24.1542 13.2314 26.216 14.3268 26.9885 15.8148L24.6097 16.6542C24.1542 16.1811 23.0925 15.6445 21.584 15.4177L21.3262 15.3788V19.3924L21.5108 19.4267C23.1394 19.717 24.4714 20.0958 25.3645 20.5221C25.7691 20.7164 26.0737 20.9067 26.2948 21.1038C26.3771 21.1798 26.4485 21.2564 26.5154 21.3272C27.0514 21.9409 27.3268 22.6221 27.3268 23.3501C27.3268 25.4386 24.9417 27.1711 21.5251 27.5597L21.3262 27.5825L21.3257 29.7985L18.8702 29.7979ZM21.3262 25.1049L21.5839 25.0654C23.5211 24.7712 24.8702 23.9243 24.8702 23.35C24.8737 22.7769 23.5239 21.9352 21.5839 21.6432L21.3262 21.6032V25.1049ZM18.6159 15.3982C16.4765 15.6834 15.1582 16.6022 15.1553 17.1353C15.1553 17.6685 16.473 18.5856 18.6159 18.8707L18.8696 18.9056V15.3639L18.6159 15.3982Z"
        fill={`${color || Colors.green}`}
      />
    </svg>
  );
};

export default MoneyIcon;

MoneyIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  bgColor: PropTypes.string
};
