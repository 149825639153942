/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const EventIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '42'}px`}
      width={`${width || '39'}px`}
      viewBox="0 0 39 42"
      css={css`
        fill: ${color || Colors.black};
        max-width: ${width || '39'}px;
        max-height: ${height || '42'}px;
        height: ${height || '42'}px;
        width: ${width || '39'}px;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.07871 31.2619H32.1303C32.6096 31.2619 32.9987 30.8728 32.9987 30.3935V0.868385C32.9987 0.389037 32.6096 0 32.1303 0H6.07871C5.59936 0 5.21033 0.389037 5.21033 0.868385V30.3935C5.21033 30.8728 5.59936 31.2619 6.07871 31.2619ZM31.2619 29.5251H6.9471V1.73677H31.2619V29.5251Z"
        fill={`${color || Colors.black}`}
      />
      <path
        d="M16.4994 41.6825C16.02 41.6825 15.631 41.2935 15.631 40.8141V30.3935C15.631 29.9141 16.02 29.5251 16.4994 29.5251C16.9787 29.5251 17.3678 29.9141 17.3678 30.3935V40.8141C17.3678 41.2935 16.9787 41.6825 16.4994 41.6825Z"
        fill={`${color || Colors.black}`}
      />
      <path
        d="M21.7097 41.6825C21.2303 41.6825 20.8413 41.2935 20.8413 40.8141V30.3935C20.8413 29.9141 21.2303 29.5251 21.7097 29.5251C22.189 29.5251 22.5781 29.9141 22.5781 30.3935V40.8141C22.5781 41.2935 22.189 41.6825 21.7097 41.6825Z"
        fill={`${color || Colors.black}`}
      />
      <path
        d="M37.3406 41.6825H0.868385C0.389037 41.6825 0 41.2935 0 40.8141C0 40.3348 0.389037 39.9457 0.868385 39.9457H37.3406C37.8199 39.9457 38.209 40.3348 38.209 40.8141C38.209 41.2935 37.8199 41.6825 37.3406 41.6825Z"
        fill={`${color || Colors.black}`}
      />
      <path
        d="M26.92 15.6309H11.289C10.8097 15.6309 10.4207 15.2419 10.4207 14.7626C10.4207 14.2832 10.8097 13.8942 11.289 13.8942H26.92C27.3993 13.8942 27.7884 14.2832 27.7884 14.7626C27.7884 15.2419 27.3993 15.6309 26.92 15.6309Z"
        fill={`${color || Colors.black}`}
      />
      <path
        d="M26.92 8.68385H11.289C10.8097 8.68385 10.4207 8.29482 10.4207 7.81547C10.4207 7.33612 10.8097 6.94708 11.289 6.94708H26.92C27.3993 6.94708 27.7884 7.33612 27.7884 7.81547C27.7884 8.29482 27.3993 8.68385 26.92 8.68385Z"
        fill={`${color || Colors.black}`}
      />
      <path
        d="M26.92 22.578H11.289C10.8097 22.578 10.4207 22.189 10.4207 21.7096C10.4207 21.2303 10.8097 20.8412 11.289 20.8412H26.92C27.3993 20.8412 27.7884 21.2303 27.7884 21.7096C27.7884 22.189 27.3993 22.578 26.92 22.578Z"
        fill={`${color || Colors.black}`}
      />
    </svg>
  );
};

export default EventIcon;

EventIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
