import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux"
import axios from 'axios';

import PlayerCard from "components/PlayerCard";
import PlayerWrapper from "components/PlayerWrapper";

import "./../../css/playerProfile.css";
import mapDispatchToProps from '../../redux/dispatchers';
import URLs from '../../utils/urls';

const PlayerProfile = ({ closeProfile }) => {
	const [playerData, setPlayerData] = useState(undefined);

	const { player } = useSelector((state) => ({
		player: state.session.selectedPlayer,
	}));

	useEffect(() => {
		if (player) {
			async function fetchData() {
				const response = await axios.post(URLs.characterProfileLink, {
					playerId: player.id,
					teamId: window.teamId,
					sessionId: window.sessionId,
					languageId: (localStorage.langId) ? localStorage.langId : 0
				})

				if (response.data.profile.id) {
					setPlayerData(response.data.profile);
				}
			}
			fetchData();
		}
	}, [ player ])

	return (
		<div className="profile-container">
			<button className="close-button" onClick={closeProfile}>X</button>
			{
				(playerData) ?
					<>
						<div className="profile-status-wrapper">
							<PlayerWrapper player={player}>
								<PlayerCard player={player} characterName={ playerData.CharacterProfileTranslation ? playerData.CharacterProfileTranslation.name : playerData.name } />
							</PlayerWrapper>
						</div>
						<div className={ `profile-table ${ playerData.Character.name }` } dangerouslySetInnerHTML={{ __html: playerData.CharacterProfileTranslation ? playerData.CharacterProfileTranslation.characterProfile : playerData.characterProfile }}></div>
					</>
					:
					null
			}
		</div>
	)
}

export default connect(null, mapDispatchToProps)(PlayerProfile);
