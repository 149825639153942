/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Colors } from 'utils';

const OrganizaitonIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '44'}px`}
      width={`${width || '36'}px`}
      viewBox="0 0 36 44"
      css={css`
        fill: ${color || Colors.black};
        max-width: ${width || '36'}px;
        max-height: ${height || '44'}px;
        height: ${height || '44'}px;
        width: ${width || '36'}px;
      `}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5735 0H4.42653C1.99227 0 0 1.97592 0 4.39166V39.6081C0 42.0239 1.87845 43.9998 4.3127 43.9998V41.804C3.09258 41.804 2.21326 40.8187 2.21326 39.6081V4.39166C2.21326 3.18115 3.2064 2.19583 4.42653 2.19583H31.5735C32.7936 2.19583 33.7867 3.18115 33.7867 4.39166V39.6081C33.7867 40.8187 32.7936 41.804 31.5735 41.804H23.6004V43.9998H31.5735C34.0077 43.9998 36 42.0239 36 39.6081V4.39166C36 1.97592 34.0077 0 31.5735 0ZM5.6838 44H21.5334V25.6686H5.6838V44ZM12.1938 23.243H5.58994V15.3717H12.1938V23.243ZM5.58994 12.8853H12.1938V5.01527H5.58994V12.8853ZM21.3045 23.243H14.7006V15.3717H21.3045V23.243ZM14.7006 12.8853H21.3045V5.01527H14.7006V12.8853ZM30.4431 23.243H23.8392V15.3717H30.4431V23.243ZM23.8392 12.8853H30.4431V5.01527H23.8392V12.8853ZM30.4431 37.7275H23.8392V25.7723H30.4431V37.7275Z"
        fill={`${color || Colors.black}`}
      />
    </svg>
  );
};

export default OrganizaitonIcon;

OrganizaitonIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
