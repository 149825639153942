import React from "react"

const columnRedStyle = {
	backgroundColor: "rgb(255, 0, 0)",
}

const columnGreenStyle = {
	backgroundColor: "rgb(0, 255, 0)",
}

const categoryDivStyle = {
	transform: "rotate(-90deg)",
	whiteSpace: "nowrap",
	width: "30px",
	marginBottom: "16px",
	fontSize: 14
}

const _SelectionLogRow = (props) => {

	const roundsList = (props.selectedCourses) ? Object.keys(props.selectedCourses) : []

	return(
		<tr>
			{
				(props.category) ?
					<td rowSpan={ props.coursesCount }>
						<div style={ categoryDivStyle }>{ props.category }</div>
					</td>
					:
					null
			}
			{ /*<td>{ props.course.id }</td>*/ }
			<td>
				{ props.course.name }
			</td>
			<td style={ columnRedStyle }>
				{ props.course.time }
			</td>
			<td style={ columnRedStyle }>
				{ props.course.money }
			</td>
			{ /*<td style={ columnGreenStyle }>
				{ props.course.skills }
			</td>
			<td style={ columnGreenStyle }>
				{ props.course.motivation }
			</td>
			<td style={ columnGreenStyle }>
				{ props.course.retentionRisk }
			</td>*/ }
			{
				props.characters.map( character => {
					let isCourseSelected = false
					let selectedCourseObj = {}
					roundsList.forEach(round => {
						if (props.selectedCourses[round][character.id]){
							props.selectedCourses[round][character.id].forEach( selectedCourse => {
								if (selectedCourse.id === props.course.id) {
									isCourseSelected = true
									selectedCourseObj = selectedCourse
								}
							})
						}
					})

					return (
						<React.Fragment key={ character.id }>
							{
								(isCourseSelected) ?
									<>
										<td className={ `row_${ character.id }` }>{ (selectedCourseObj.SimtalentCourseImpacts && selectedCourseObj.SimtalentCourseImpacts.time !== undefined) ? selectedCourseObj.SimtalentCourseImpacts.time : props.course.time }</td>
										<td className={ `row_${ character.id }` }>{ (selectedCourseObj.SimtalentCourseImpacts && selectedCourseObj.SimtalentCourseImpacts.money !== undefined) ? selectedCourseObj.SimtalentCourseImpacts.money : props.course.money }</td>
										<td className={ `row_${ character.id }` }>{ (selectedCourseObj.SimtalentCourseImpacts && selectedCourseObj.SimtalentCourseImpacts.skills !== undefined) ? selectedCourseObj.SimtalentCourseImpacts.skills : props.course.skills }</td>
										<td className={ `row_${ character.id }` }>{ (selectedCourseObj.SimtalentCourseImpacts && selectedCourseObj.SimtalentCourseImpacts.motivation !== undefined) ? selectedCourseObj.SimtalentCourseImpacts.motivation : props.course.motivation }</td>
										<td className={ `row_${ character.id }` }>{ (selectedCourseObj.SimtalentCourseImpacts && selectedCourseObj.SimtalentCourseImpacts.retentionRisk !== undefined) ? selectedCourseObj.SimtalentCourseImpacts.retentionRisk : props.course.retentionRisk }</td>
									</>
									:
									<>
										<td className={ `row_${ character.id }` }></td>
										<td className={ `row_${ character.id }` }></td>
										<td className={ `row_${ character.id }` }></td>
										<td className={ `row_${ character.id }` }></td>
										<td className={ `row_${ character.id }` }></td>
									</>
							}
						</React.Fragment>
					)
				})
			}
		</tr>
	)
}

export default _SelectionLogRow
