import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux'

import mapDispatchToProps from './../../../redux/dispatchers'
import * as RealtimeConnection from '../../../utils/RealtimeConnection'
import Translate from '../../../utils/Translate'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '2rem',
	},
};

Modal.setAppElement('#root');

const ConfirmPurchase = ({ playerName, session, updateSession }) => {
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const openModal = () => {
		if (session.allowCoursePurachse) {
			setIsOpen(true);
		}
	}

	const cancelAction = () => setIsOpen(false)

	const confirmAction = () => {
		setIsOpen(false);

		RealtimeConnection.confirmCourses(session.roundNumber, session.activeSelectedCourses)
		//updateSession({ type:  "money" , value: session.amount - course.money })
		//updateSession({ type:  "time" , value: session.time - course.time })
		//updateSession({ type:  "spent" , money: session.spent.money + course.money, time: session.spent.time + course.time })
	}

	return (
		<div>
			{
				(session.eventCardAppearanceStatus.allowDevelopmentSelection &&
					<button onClick={openModal} disabled={ !session.allowCoursePurachse } className="purchase-button">
						<Translate alias="Board" word="Apply" />
					</button>
				)
			}
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Example Modal"
			>
				<h2 className="modal-title">{ <Translate alias="Board" word="Apply" /> }</h2>
					<p>
						{ <Translate alias="Board" word="Are you sure you want to apply the selected options?" /> }
						 <br />
						{ <Translate alias="Board" word="no further selection will be possible!!" /> }
						</p>
				<div className="button-div">
					<button className="modal-button red" onClick={cancelAction}>
						{ <Translate alias="Board" word="No" /> }
					</button>
					<button className="modal-button" onClick={confirmAction}>
						{ <Translate alias="Board" word="Yes" /> }
					</button>
				</div>
			</Modal>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		session: state.session
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPurchase)
