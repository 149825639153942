const dispatchToProps = (dispatch) => {
	return {
		updateRoundNumber: () => dispatch({ type: 'UPDATE_ROUND' }),
		restoreRoundNumber: (round) => dispatch({ type: 'RESTORE_ROUND', round }),

		updateSession: (data) => dispatch({ type: 'UPDATE_SESSION', data: data }),
		updateSession_2: (data) => dispatch({ type: 'UPDATE_SESSION_2', data: data }),
		updateSessionTime: (data) => dispatch({ type: 'UPDATE_SESSION_TIME', data}),
		updateCharacter: (data) => dispatch({ type: 'UPDATE_CHARACTER', data: data }),
		decreaseCharacter: (data) => dispatch({ type: 'DECREASE_CHARACTER', data: data }),
		increaseCharacter: (data) => dispatch({ type: 'INCREASE_CHARACTER', data: data }),
		updateCharacterValues: (data) => dispatch({ type: 'UPDATE_SESSION_CHARACTER', data: data }),

		restoreSession: (data) => dispatch({ type: 'RESTORE_SESSION', data: data }),
		restoreCharacters: (data) => dispatch({ type: 'RESTORE_CHARACTERS', data: data }),
		restoreSessionData: (data) => dispatch({ type: 'RESTORE_SESSION_DATA', data: data }),
		restoreSelectedCourses: (courses) => dispatch({ type: 'RESTORE_SELECTED_COURSES', courses: courses }),

		appendCharacter: (category, index, data) => dispatch({ type: 'ADD_CHARACTER', category: category, index: index, data: data }),
		removeCharacter: (category, index, data) => dispatch({ type: 'REMOVE_CHARACTER', category: category, index: index, data: data }),

		updateFiles: (value) => dispatch({type: 'UPDATE_FILES', value: value}),
		addFile: (value) => dispatch({type: 'ADD_FILE', value: value}),
		removeFile: (id) => dispatch({type: 'REMOVE_FILE', id: id}),

		changeTeamName: (name) => dispatch({type: 'CHANGE_TEAM_NAME', name: name}),

		openDevelopment: () => dispatch({ type: 'OPEN_DEVELOPMENT' }),
		openSelectionLog: () => dispatch({ type: 'OPEN_SELECTION_LOG' }),
		openEvent: () => dispatch({ type: 'OPEN_EVENT' }),
		selectCourse: (playerId, course) => dispatch({ type: 'SELECT_COURSE', playerId, course }),
		deSelectCourse: (playerId, course) => dispatch({ type: 'DESELECT_COURSE', playerId, course }),
		coursesSubmitted: (courses) => dispatch({ type: 'COURSES_SUBMITTED', courses: courses }),
		purchaseCourseStatus: (status) => dispatch({ type: 'PURCHASE_COURSE_STATUS', status }),
		updateCurrentEvent: (event) => dispatch({ type: 'UPDATE_CURRENT_EVENT', event }),
		UpdateLastEventId: (event) => dispatch({ type: 'UPDATE_LAST_EVENT', event }),
		selectEventOption: (option) => dispatch({ type: 'SELECT_EVENT_OPTION', option }),
		updateEventCards: (cards) => dispatch({ type: 'UPDATE_EVENT_CARDS', cards }),
		selectedCard: (card) => dispatch({ type: 'SELECT_CARD', card }),
		updateEventView: (view) => dispatch({ type: 'UPDATE_EVENT_VIEW', view }),
		updateCardEventStatus: (data) => dispatch({ type: 'UPDATE_EVENT_CARD_STATUS', data }),
		restoreCardEventStatus: (data) => dispatch({ type: 'RESTORE_EVENT_CARD_STATUS', data }),

		openProfile: (player) => dispatch({ type: 'OPEN_PROFILE', player }),
		closeProfile: () => dispatch({ type: 'CLOSE_PROFILE' }),

		restoreLocalization: (localizations) => dispatch({ type: 'RESTORE_LOCALIZATION', localizations }),
		updateWhiteCharacterStatus: (status) => dispatch({ type: 'UPDATE_WHITE_CHARACTER_STATUS', status }),
		updateWhiteCharacterData: (data) => dispatch({ type: 'UPDATE_WHITE_CHARACTER_DATA', data }),
		updateCounter: (value) => dispatch({type: 'UPDATE_COUNTER', value: value}),
	};
};

export default dispatchToProps;
