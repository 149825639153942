import initState from "../states/board-init-state";

const reducer = (state = initState, action) => {
	if (action.category !== undefined) {
		switch(action.type) {
			case 'ADD_CHARACTER': {
				let characters = [ ...state[action.category][action.index].players ]
				characters.push(action.data)

				return {
					...state,
					[action.category]: state[action.category].map( (content, index) => 
						(index === action.index) ? {...content, players: characters} : content
					)
				}
			}
			case 'REMOVE_CHARACTER': {
				let characters = [ ...state[action.category][action.index].players ]
				let newCharacters = characters.filter( character => {
					if (character.id === action.data.id) {
						return false
					}
					return true
				})

				return {
					...state,
					[action.category]: state[action.category].map( (content, index) => 
						(index === action.index) ? {...content, players: newCharacters} : content
					)
				}
			}
			default:
				break;
		}
	}else{
		switch(action.type) {
			case 'RESTORE_SESSION': {
				state = action.data
				return {
					...state
				}
			}
			default:
				break;
		}
	}
	return state
}

export default reducer;